import React, { useState, useEffect } from "react";



export default function Preloader(props){
    const [useShow, setShow] = useState(props.show);

    return (
        <div className="preloader-base" style={{ display: props.show }}>
            <div className="load">
                <hr/><hr/><hr/><hr/>
            </div>
        </div>
    );
}