import {React, useState} from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box, IconButton, Link } from "@material-ui/core";
import Icon from "@mdi/react";

// Urls
import Urls from "../../urls";

//icons
import {
    mdiFacebook as FacebookIcon,
    mdiTwitter as TwitterIcon,
    mdiGithub as GithubIcon,
} from "@mdi/js";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";
import axios from "axios";

const ApiURL = process.env.REACT_APP_ROOT;

function Layout(props) {
    var classes = useStyles();

    // global
    var layoutState = useLayoutState();

    //User Type
    const [useUserType, setUserType] = useState(0);
    const [useUserTypeName, setUserTypeName] = useState("");
    const [useIsAdmin, setIsAdmın] = useState(false);
    const [useUserId, setUserId] = useState(0);
    axios({
        method: "GET",
        url: ApiURL + "/get_current_user",
        headers: {
            Authorization: `Bearer ${props.token}`
        }
    }).then(resp => {
        let payload = JSON.parse(resp.data);
        setUserType(payload.user_type);
        setUserId(payload.id);
        if(payload.user_type == -1){
            setUserTypeName("Modifier");
            setIsAdmın(true);
        } else {
            setUserTypeName("Viewer");
            setIsAdmın(false);
        }
    });
    

    return (
        <div className={classes.root}>
            <>
                <Header history={props.history} token={props.token} />
                <Sidebar isAdmin={useIsAdmin} />
                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >
                    <div className={classes.fakeToolbar} />
                    <Switch>
                        {Urls.map((route, routeIndex) => {
                            if( !route.isAdminUrl || (route.isAdminUrl && useIsAdmin) ){
                                return (
                                    <Route key={"route-" + routeIndex} path={route.path} component={() => {
                                        const component = route.component({
                                            token: props.token,
                                            userType: useUserType,
                                            userTypeName: useUserTypeName,
                                            isAdmin: useIsAdmin,
                                            isModifier: useUserType == -1,
                                            userId: useUserId,
                                            hasSeen: props.hasSeen,
                                            setHasSeen: props.setHasSeen,
                                        });
                                        return component;
                                        // <route.component token={props.token} userType={useUserType} userTypeName={useUserTypeName} isAdmin={useIsAdmin} /> 
                                    }}/>
                                )
                            }
                        })}
                    </Switch>
                    
                </div>
            </>
        </div>
    );
}

export default withRouter(Layout);
