import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ExpandIcon,
} from "../../components/Accordion/Accordion";
import Typography from "@material-ui/core/Typography";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Button,
} from "@material-ui/core";

const roles = [
    "Mitarbeiter",
    "Vorarbeiter",
    "Meister",
    "Abteilungsleiter",
    "Produktionsleiter",
];

export default function Filter(props) {
    const ApiURL = process.env.REACT_APP_ROOT;

    const [useFilterProps, setFilterProps] = useState({});

    // Data
    
    

    // Accordion
    const [useAccordionExpanded, setAccordionExpanded] = useState(false);
    const handleAccordion = (panel) => (event, newExpanded) => {
        setAccordionExpanded(newExpanded ? panel : false);
    };

    // Filter Values Result
    const [useResult, setResult] = useState([]);

    // Values Changed
    const [useRoleChanged, setRoleChanged] = useState(false);
    const [useDepartmentChanged, setDepartmentChanged] = useState(false);
    const [useEmploymentRelationshipChanged, setEmploymentRelationshipChanged] =
        useState(false);   

    // Role
    const [useRole, setRole] = useState(9999999999999999999);
    const handleRoleChange = (event) => {
        setRole(event.target.value);
        setRoleChanged(true);
    };

    // Department
    const [useDepartment, setDepartment] = useState(9999999999999999999);
    const handleDepartmentChange = (event) => {
        setDepartment(event.target.value);
        setDepartmentChanged(true);
    };

    // EmploymentRelationship
    const [useEmploymentRelationship, setEmploymentRelationship] = useState("");
    const handleEmploymentRelationshipChange = (event) => {
        setEmploymentRelationship(event.target.value);
        setEmploymentRelationshipChanged(true);
    };

    useEffect(() => {
        let query_params = "";

        if (useRoleChanged) {
            query_params += "?role=" + useRole;
        } else {
            query_params += "?role=*";
        }

        if (useDepartmentChanged) {
            query_params += "&department=" + useDepartment;
        } else {
            query_params += "&department=*";
        }

        if (useEmploymentRelationshipChanged) {
            query_params +=
                "&employment_relationship=" + useEmploymentRelationship;
        } else {
            query_params += "&employment_relationship=*";
        }

        //console.log([useRole, useDepartment, useEmploymentRelationship]);

        axios({
            method: "GET",
            url: ApiURL + `/employees/filter_by_props` + query_params,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then((resp) => {
            let payload = JSON.parse(resp.data);
            // console.log(payload);
            setResult(payload);
        });
    }, [useRole, useDepartment, useEmploymentRelationship]);

    // useEffect(() => {
    //     console.log(useFilterProps);
    // }, [useFilterProps]);

    useEffect(() => {
        props.filterProps(useResult);
    }, [useResult]);

    const handleReset = () => {
        setRoleChanged(false);
        setDepartmentChanged(false);
        setEmploymentRelationshipChanged(false);
        setRole(9999999);
        setDepartment(9999999);
        setEmploymentRelationship("");
        setResult([]);
    }

    return (
        <div className="filter-container">
            <Accordion
                square
                expanded={useAccordionExpanded === "filter-panel-1"}
                onChange={handleAccordion("filter-panel-1")}
            >
                <AccordionSummary
                    aria-controls="filter-panel-1-content"
                    id="filter-panel-1-header"
                    expanded={{}}
                    expandIcon={<ExpandIcon />}
                >
                    <Typography>Filter</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Rolle
                                </InputLabel>
                                {props.multipleData.role != undefined && (
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={useRole}
                                        label="Rolle"
                                        onChange={handleRoleChange}
                                    >
                                        {props.multipleData.role.map(
                                            (value, key) => (
                                                <MenuItem
                                                    value={value}
                                                    key={key}
                                                >
                                                    {roles[value]}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Abteilung
                                </InputLabel>
                                {props.multipleData.department != undefined && (
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={useDepartment}
                                        label="Abteilung"
                                        onChange={handleDepartmentChange}
                                    >
                                        {props.multipleData.department.map(
                                            (value, key) => (
                                                <MenuItem
                                                    value={value.id}
                                                    key={key}
                                                >
                                                    {value.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Employment Relationship
                                </InputLabel>
                                {props.multipleData.employment_relationship !=
                                    undefined && (
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={useEmploymentRelationship}
                                        label="Abteilung"
                                        onChange={
                                            handleEmploymentRelationshipChange
                                        }
                                    >
                                        {props.multipleData.employment_relationship.map(
                                            (value, key) => (
                                                <MenuItem
                                                    value={value}
                                                    key={key}
                                                >
                                                    {value}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" onClick={handleReset}>
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
