import React, { useState, useEffect } from "react";

import Preloader from "../../components/Preloader/Preloader";

import { Grid, Button, Tab } from "@material-ui/core";
import JSONDigger from "json-digger";

import useStyles from "./styles";
import Widget from "../../components/Widget";
import OrganizationChart from "../../components/Chart/ChartContainer";
import PageTitle from "../../components/PageTitle";
import "../../assets/css/orgchart-levels.css";
import axios from "axios";
import notification from "../../helpers/SnackbarUtils";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { renderIf } from "../../helpers/helper";
// Modal
// !
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TextField,
} from "@material-ui/core";

// !
import {Search as SearchIcon} from '@material-ui/icons';

import MultiNote from "../../components/MultiNote/MultiNote";
import Filter from "../../components/Filter/Filter";
import Exports from "../../components/ExportedXLSs/ExportedXLSs";
const env = process.env;

const rootURL = env.REACT_APP_ROOT;
const ApiURL = process.env.REACT_APP_ROOT;

export default function Dashboard(props) {
    const [useHighlightedNodes, setHighlightedNodes] = useState([]);

    const [useDashboardMount, setDashboardMount] = useState(Math.random());

    const [useShowPreloader, setShowPreloader] = useState("block");

    const [isMounted, setIsMounted] = useState(false);

    const indicator = (element) => {};

    const renderIfIsAdmin = (component) => {
        return renderIf(props.isAdmin, component);
    };

    var classes = useStyles();

    const useModifier = props.isModifier;
    // const [useModifier, setModifier] = useState(false);

    const [useReRender, setReRender] = useState(Math.random());

    const [useOrgChart, setOrgChart] = useState({});

    const [useLast24HoursLogsPopup, setLast24HoursLogsPopup] = useState(false);
    const [useLast24HoursLogs, setLast24HoursLogs] = useState([]);
    const handleLast24HoursLogsPopupClose = () => { 
        setLast24HoursLogsPopup(false);
        props.setHasSeen();
    };
    
    useEffect(() => {
        async function fetch(){
            const respChartSchema = await axios({
                method: "GET",
                url: rootURL + "/employees/chart_schema",
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            })
            await setOrgChart(JSON.parse(respChartSchema.data));
            
            
            const userId = props.userId;
            if(userId != 0){
                if(!props.hasSeen){
                    const respLast24HoursLogs = await axios({
                        method: "GET",
                        url: rootURL + "/change_log/last24Hours",
                        headers: {
                            Authorization: `Bearer ${props.token}`,
                        }
                    });
                    await setLast24HoursLogs(respLast24HoursLogs.data);
                    await setLast24HoursLogsPopup(true);
                }
            }
        }

        fetch();
        
        // setModifier(props.userType == -1);

    }, []);

    useEffect(() => {
        axios({
            method: "GET",
            url: rootURL + "/employees/chart_schema",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                setOrgChart(JSON.parse(resp.data));
                // if (props.userType == -1) {
                //     setModifier(true);
                // }
            })
            .then(() => {
                setShowPreloader("none");
                setIsMounted(true);
            });
    }, [useReRender]);

    // Filter Multiple Columns
    const [useMultipleData, setMultipleData] = useState({});

    useEffect(() => {
        axios({
            method: "GET",
            url: ApiURL + `/employees/multiple_columns`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                setMultipleData(JSON.parse(resp.data));
            })
            .then(() => {
                //console.log(useMultipleData);
            });
    }, []);

    const [useFilterProps, setFilterProps] = useState([]);

    const handleFilterPropsChange = (object) => {
        setFilterProps(object);
    };

    useEffect(() => {
        let arr = [];
        useFilterProps.forEach((item) => {
            arr.push("n" + item);
        });
        setHighlightedNodes(arr);
    }, [useFilterProps]);

    // Source Data
    const dataSourceDigger = new JSONDigger(useOrgChart, "id", "children");

    //! Handles
    const saveHandler = () => {
        console.log(dataSourceDigger.ds);
        notification.info("Organigram wird gespeichert...");
        axios({
            method: "POST",
            url: rootURL + "/employees/chart_schema_save",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: {
                schema: JSON.stringify(dataSourceDigger.ds),
            },
        }).then((resp) => {
            notification.success("Organigram gespeichert!");
        });
    };

    let timer;
    let timerBool = false;
    const nodeClickHandler = (object) => {
        clearTimeout(timer);

        setTimeout(() => {
            //console.log(object);
        }, 2000);

        if (!timerBool) {
            timerBool = true;
            timer = setTimeout(() => {
                timerBool = false;
            }, 300);
        } else {
            timerBool = false;
            nodeDoubleClickHandler(object);
        }
    };

    //ReactDOM.findDOMNode(this).addEventListener('nv-event', this._handleNVEvent);

    const nodeDoubleClickHandler = (object) => {
        let id = object.id;
        id = parseInt(id.replace("n", ""));
        handleModalContentAndOpen(id);
    };
    //

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const [useLogsActiveUserName, setLogsActiveUserName] = useState("");
    // Modal
    const [useModalOpen, setModalOpen] = useState(false);
    const [useModalTitle, setModalTitle] = useState("");
    const [useModalContent, setModalContent] = useState(<></>);
    const [useRoles, setRoles] = useState([]);

    useEffect(() => {
        axios({
            method: "GET",
            url: rootURL + "/return_json/constants/roles",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then((resp) => {
            setRoles(JSON.parse(resp.data));
        });
    }, []);

    const handleModalToggle = () => {
        setModalOpen(!useModalOpen);
    };

    const [useSetModalActiveUserId, setSetModalActiveUserId] = useState(0);

    const handleModalContentAndOpen = (objectID) => {
        setSetModalActiveUserId(objectID);
        axios({
            method: "GET",
            url: rootURL + "/employees/" + objectID,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                let data = resp.data;

                setLogsActiveUser(data.id);
                return {
                    data: data,
                };
            })
            .then((ret) => {
                let parentID = ret.data.parent;
                axios({
                    method: "GET",
                    url: rootURL + "/employees/" + parentID + "/fullname",
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    },
                })
                    .then((resp) => {
                        let name = resp.data;
                        let retObject = ret.data;
                        retObject.parent = name;
                        return retObject;
                    })
                    .then((ret) => {
                        //:y Here is the role problem
                        ret.role = useRoles[ret.role];
                        //ret.role = ret.role;

                        return ret;
                    })
                    .then(async (ret) => {
                        let department = await axios({
                            method: "GET",
                            url: rootURL + "/departments/" + ret.department,
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        });
                        ret.department = department.data.label;
                        ret.quit = "No";
                        if (ret.has_leaved_company) {
                            ret.quit = "Yes";
                        }

                        let notes = await axios({
                            method: "GET",
                            url: rootURL + `/employees/${ret.id}/notes`,
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        });

                        let payload = await JSON.parse(notes.data);

                        ret.notes = await payload;

                        await handleModalContent(ret);
                        setLogsActiveUserName(ret.name + " " + ret.surname);
                        await handleModalToggle();
                        //await setSetModalActiveUserId(ret.id);
                    });
            });
    };

    const handleModalContent = (object) => {
        setModalContent(
            <TableContainer>
                <Table className={classes.table} aria-label="Employee Table">
                    <TableBody>
                        <TableRow>
                            <TableCell>ID: </TableCell>
                            <TableCell>{object.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Vorname: </TableCell>
                            <TableCell>{object.surname}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name: </TableCell>
                            <TableCell>{object.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Geburtsdatum: </TableCell>
                            <TableCell>{object.date_of_birth}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Vorgesetzer: </TableCell>
                            <TableCell>{object.parent}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Rolle: </TableCell>
                            <TableCell>{object.role}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Arbeitswochen: </TableCell>
                            <TableCell>{object.working_weeks}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Arbeitsverhältnis: </TableCell>
                            <TableCell>
                                {object.employment_relationship}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Beschäftigungsverhältnis: </TableCell>
                            <TableCell>{object.employment_type}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Abteilung: </TableCell>
                            <TableCell>{object.department}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Beschäftigungsverhältnis beendet?:{" "}
                            </TableCell>
                            <TableCell>{object.quit}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Anmerkungen</TableCell>
                        </TableRow>
                        {object.notes.map((note, noteIndex) => {
                            return (
                                <TableRow key={"note-row-" + noteIndex}>
                                    <TableCell>{note.date}</TableCell>
                                    <TableCell>{note.note}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    // Logs Modal
    const [useLogsModalOpen, setLogsModalOpen] = useState(false);
    const [useLogs, setLogs] = useState([]);
    const [useLogsActiveUser, setLogsActiveUser] = useState(1);
    const [useLogsPage, setLogsPage] = useState(1);

    const handleLogsModalToggle = () => {
        setLogsModalOpen(!useLogsModalOpen);
    };

    useEffect(() => {
        if (!useLogsModalOpen) {
            setLogsPage(1);
        }
    }, [useLogsActiveUser]);

    const handleLogModalOpen = () => {
        handleLogsModalToggle();
    };

    useEffect(() => {
        axios({
            method: "GET",
            url: rootURL + `/employees/${useLogsActiveUser}/logs`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                let payload = JSON.parse(resp.data);
                setLogs(payload);
                return payload;
            })
            .then((res) => {});
    }, [useLogsActiveUser]);

    const handleLogLoadMore = () => {
        let page = useLogsPage + 1;
        setLogsPage(page);
        axios({
            method: "GET",
            url: rootURL + `/employees/${useLogsActiveUser}/logs?page=${page}`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then((resp) => {
            let payload = JSON.parse(resp.data);
            if (payload.length > 0) {
                let logs = useLogs;
                logs = logs.concat(JSON.parse(resp.data));
                setLogs(logs);
            }
        });
    };

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    //:y Not working Stuff
    const [useNotWorkingStaff, setNotWorkingStaff] = useState([]);
    const handleReRender = () => {
        setReRender(Math.random());
    };
    useEffect(() => {
        axios({
            method: "GET",
            url: rootURL + "/employees/not_working_staff",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then((resp) => {
            let payload = JSON.parse(resp.data);
            setNotWorkingStaff(payload);
        });
    }, [useReRender]);

    useEffect(() => {}, [useReRender]);

    //:y Export
    const handleExport = () => {
        handleExportModalToggle();
    };

    const [useExportModalOpen, setExportModalOpen] = useState(false);

    const handleExportModalToggle = () => {
        setExportModalOpen(!useExportModalOpen);
    };

    const [useParentTrees, setParentTrees] = useState([]);
    const [useAllEmployees, setAllEmployees] = useState([]);
    useEffect(() => {
        axios({
            method: "GET",
            url: rootURL + `/employees/parent_trees_reversed`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                let response = JSON.parse(resp.data);
                let resArr = [];
                response.forEach((parentItem) => {
                    let parentTreeText = "";
                    let i = 0;
                    parentItem.parent_tree.forEach(
                        (parentTreeItem, parentTreeItemIndex) => {
                            if (
                                parentTreeItemIndex ==
                                parentItem.parent_tree.length - 1
                            ) {
                                parentTreeItem.name = (
                                    <b key={Math.random()}>{parentTreeItem.name}</b>
                                );
                            }
                            parentTreeText = [
                                parentTreeText,
                                parentTreeItem.name,
                            ];

                            if (
                                parentTreeItemIndex !=
                                parentItem.parent_tree.length - 1
                            ) {
                                parentTreeText = [parentTreeText, " >> "];
                            }
                        }
                    );
                    let resObject = {
                        id: parentItem.id,
                        text: parentTreeText,
                    };
                    resArr.push(resObject);
                });

                setParentTrees(resArr);
                return resArr;
            })
            .then((resArr) => {
                let allEmployeesArr = [];
                resArr.forEach((item) => {
                    allEmployeesArr.push([item.id, item.text]);
                });
                setAllEmployees(allEmployeesArr);
            });
    }, []);

    const [useExportCheckedIds, setExportCheckedIds] = useState([]);
    const exportCheck = (event) => {
        let arr = useExportCheckedIds;
        if (event.target.checked) {
            arr.push(parseInt(event.target.value));
        } else {
            const index = arr.indexOf(parseInt(event.target.value));
            if (index > -1) {
                arr.splice(index, 1);
            }
        }
        setExportCheckedIds(arr);
    };

    const [useRemoveFromDatabase, setRemoveFromDatabase] = useState(false);
    const handleRemoveFromDatabase = (event) => {
        setRemoveFromDatabase(event.target.checked);
    };

    const handleExportSend = () => {
        axios({
            method: "POST",
            url: rootURL + "/import_export/export",
            headers: {
                Authorization: `Bearer ${props.token}`,
                responseType: "blob",
            },
            data: {
                employees: useExportCheckedIds,
                remove_from_database: useRemoveFromDatabase,
            },
        })
            .then((resp) => {
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "export.csv"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .then(() => {
                if (useRemoveFromDatabase) {
                    setReRender(Math.random());
                }
            });
        //console.log("Remove From Database");
        //console.log(useRemoveFromDatabase);
        //console.log("Arr");
        //console.log(useExportCheckedIds);
    };

    //:y Import
    const [useImportModalOpen, setImportModalOpen] = useState(false);

    const handleImportInputOnChange = (event) => {
        console.log("file selected!");
    };

    const handleImport = () => {
        const importInput = document.createElement("input");
        importInput.setAttribute("type", "file");
        setImportModalOpen(true);
    };

    const handleModalClose = () => {
        setImportModalOpen(false);
    };

    const [useSelectedImportFile, setSelectedImportFile] = useState(null);

    const handleSelectedImportFileChange = (event) => {
        setSelectedImportFile(event.target.files[0]);
    };

    const handleFormSend = () => {
        const formData = new FormData();
        formData.append(
            "myFile",
            useSelectedImportFile,
            useSelectedImportFile.name
        );

        axios({
            method: "POST",
            url: ApiURL + `/import_export/import`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: formData,
        }).then((resp) => {
            //console.log(resp);
            setReRender(Math.random());
            notification.success(resp.data);
        });
    };

    const [useDragOnlyRelatedObject, setDragOnlyRelatedObject] = useState(true);
    const handleDragOnlyRelatedObject = (event) => {
        setDragOnlyRelatedObject(event.target.checked);
    };

    const [useMultiNoteEmployee, setMultiNoteEmployee] = useState(0);
    const [useMultiNoteModal, setMultiNoteModal] = useState(false);

    const handleMultiNoteModal = (employeeId) => {
        setMultiNoteModal(!useMultiNoteModal);
        setMultiNoteEmployee(employeeId);
        console.log(employeeId);
    };

    // Search
    const [useSearchValue, setSearchValue] = useState("");
    const handleSearchValue = (event) => {
        setSearchValue(event.target.value);
    }

    const handleSearchFormSubmit = (event) => {
        axios({
            method: "GET",
            url: rootURL + "/employees/search?s=" + useSearchValue,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then(resp => {
            const payload = JSON.parse(resp.data);
            setResult(payload);
        }).catch((error) => {
            setResult([]);
            notification.error("Arbeiter nicht gefunden.");
        });
        event.preventDefault();
    }

    const [useResult, setResult] = useState([]);

    return (
        <>
            <Preloader show={useShowPreloader} />
            <MultiNote
                title={useLogsActiveUserName + "Mitarbeiternotizen "}
                open={useMultiNoteModal}
                modifier={useModifier}
                token={props.token}
                itemId={useSetModalActiveUserId}
                isMounted={isMounted}
            />
            <Dialog
                open={useImportModalOpen}
                onClose={handleModalClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Import Datei Hochladen
                </DialogTitle>
                <DialogContent>
                    <input
                        accept="image/*"
                        className={classes.input}
                        style={{ display: "none" }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleSelectedImportFileChange}
                    />
                    <label htmlFor="raised-button-file">
                        <Button
                            variant="raised"
                            component="span"
                            className={classes.button}
                        >
                            Wähle eine Datei aus
                        </Button>
                    </label>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleFormSend} color="primary">
                        Senden
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <form onSubmit={handleSearchFormSubmit}>
                        <TextField
                            id="standard-name"
                            label="Suchen..."
                            value={useSearchValue}
                            onChange={handleSearchValue}
                            size="small"
                            variant="outlined"
                            InputProps={{endAdornment: (
                                <Button type="submit" className="empty-button">
                                    <SearchIcon />
                                </Button>
                            )}}
                        />
                    </form>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={useDragOnlyRelatedObject}
                                checked={useDragOnlyRelatedObject}
                                onClick={handleDragOnlyRelatedObject}
                                onChange={handleDragOnlyRelatedObject}
                            />
                        }
                        label="Nur verwandtes Objekt ziehen"
                    />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    {renderIfIsAdmin(
                        <div className="import-export-row">
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={handleExport}
                            >
                                Exportieren
                            </Button>
                            <Button
                                variant="outlined"
                                size="medium"
                                color="secondary"
                                onClick={handleImport}
                            >
                                Importieren
                            </Button>
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} className="noPaddingTB">
                    <Exports token={props.token} />
                </Grid>
                <Grid item xs={12} className="noPaddingTB">
                    <Filter
                        token={props.token}
                        multipleData={useMultipleData}
                        filterProps={handleFilterPropsChange}
                        result={useResult}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Widget
                        title="Organigramm"
                        upperTitle
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        button={renderIfIsAdmin(
                            <Button
                                variant="contained"
                                size="medium"
                                color="secondary"
                                onClick={saveHandler}
                            >
                                Speichern
                            </Button>
                        )}
                    >
                        <div className={classes.visitsNumberContainer}>
                            <OrganizationChart
                                datasource={useOrgChart}
                                draggable={useModifier}
                                collapsible={false}
                                zoom={true}
                                pan={true}
                                onClickNode={nodeClickHandler}
                                onDrag={nodeClickHandler}
                                dragOnlyRelatedObject={
                                    !useDragOnlyRelatedObject
                                }
                                highlightedNodes={useHighlightedNodes}
                            />
                        </div>
                    </Widget>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Widget
                        title="Nicht arbeitendes Personal"
                        upperTitle
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        button={
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={handleReRender}
                            >
                                Aktualisieren
                            </Button>
                        }
                    >
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-label="Nicht arbeitendes Personal"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Arbeitswochen</TableCell>
                                        <TableCell>Ferien</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {useNotWorkingStaff.map(
                                        (employee, employeeKey) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        {employeeKey + 1}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                handleModalContentAndOpen(
                                                                    employee.id
                                                                );
                                                            }}
                                                        >
                                                            {employee.fullname}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        {employee.working_weeks}
                                                    </TableCell>
                                                    <TableCell>
                                                        {employee.holidays_raw}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Widget>
                </Grid>
            </Grid>
            {/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
            <Dialog
                open={useModalOpen}
                onClose={handleModalToggle}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {useModalTitle}
                </DialogTitle>
                <DialogContent>{useModalContent}</DialogContent>
                <DialogActions>
                    <Button onClick={handleLogModalOpen} color="primary">
                        Protokolle öffnen
                    </Button>
                    <Button onClick={handleModalToggle} color="primary">
                        Schließen
                    </Button>
                    <Button
                        onClick={() =>
                            handleMultiNoteModal(useSetModalActiveUserId)
                        }
                        color="secondary"
                    >
                        Mitarbeiternotizen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={useLogsModalOpen}
                onClose={handleLogsModalToggle}
                aria-labelledby="logs-dialog-title"
            >
                <DialogTitle id="logs-dialog-title">
                    {useLogsActiveUserName}
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label="Employee Log Table"
                        >
                            <TableBody>
                                {useLogs.map((log) => {
                                    return (
                                        <TableRow>
                                            <TableCell
                                                key={"log" + Math.random()}
                                            >
                                                {log.log}
                                            </TableCell>
                                            <TableCell
                                                key={"log-date" + Math.random()}
                                            >
                                                {log.date}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                <TableRow
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        onClick={handleLogLoadMore}
                                        color="primary"
                                        style={{ display: "flex" }}
                                    >
                                        Mehr laden
                                    </Button>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogsModalToggle} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
            {/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

            <Dialog
                open={useExportModalOpen}
                onClose={handleExportModalToggle}
                aria-labelledby="export-dialog-title"
            >
                <DialogTitle id="export-dialog-title">Exportieren</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table className={classes.table} aria-label="">
                            <TableBody>
                                {useAllEmployees.map((employee) => {
                                    return (
                                        <TableRow key={"allemployees-"+Math.random()}>
                                            <TableCell>
                                                <Checkbox
                                                    onChange={exportCheck}
                                                    color="primary"
                                                    value={employee[0]}
                                                    inputProps={{
                                                        "aria-label":
                                                            "secondary checkbox",
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>{employee[1]}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={useRemoveFromDatabase}
                                onChange={handleRemoveFromDatabase}
                                name="removeFromDatabase"
                            />
                        }
                        label="Aus der Datenbank entfernen"
                        style={{ float: "left" }}
                    />
                    <Button
                        onClick={handleExportSend}
                        color="primary"
                        variant="contained"
                    >
                        Exportieren
                    </Button>
                    <Button onClick={handleExportModalToggle} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog 
                open={useLast24HoursLogsPopup}
                onClose={handleLast24HoursLogsPopupClose}
                aria-labelledby="last24HourLogs"
            >
                <DialogTitle id="last24HourLogs">
                    Änderungen in den letzten 24 Stunden
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table className={classes.table} aria-label="">
                            <TableBody>
                                {useLast24HoursLogs.map((log) => {
                                    return (
                                        <TableRow key={"last24hours-"+log.id}>
                                            <TableCell>
                                                {log.log}
                                            </TableCell>
                                            <TableCell>
                                                {log.date}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLast24HoursLogsPopupClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
