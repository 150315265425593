import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import JSONDigger from "json-digger";

import useStyles from "./styles";
import Widget from "../../components/Widget/Widget";
import OrganizationChart from "../../components/Chart/ChartContainer";
import PageTitle from "../../components/PageTitle/PageTitle";
import "../../assets/css/orgchart-levels.css";
import axios from "axios";
import notification from "../../helpers/SnackbarUtils";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { renderIf } from "../../helpers/helper";
// Modal
// !
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
} from "@material-ui/core";
// !
const test_data = require("../../data/test_data.json");
const env = process.env;
const rootURL = env.REACT_APP_ROOT;

export default function NotWorking(props) {

    

    var classes = useStyles();

    const [useReRender, setReRender] = useState(Math.random());

    const [useModalContent, setModalContent] = useState(<></>);
    const [useLogsActiveUserName, setLogsActiveUserName] = useState("");
    
    //:y Not working Stuff
    const [useNotWorkingStaff, setNotWorkingStaff] = useState([]);
    const handleReRender = () => {
        setReRender(Math.random());
    };

    const [useRoles, setRoles] = useState([]);
    const [useModalOpen, setModalOpen] = useState(false);
    const handleModalToggle = () => {
        setModalOpen(!useModalOpen);
    };

    useEffect(() => {
        axios({
            method: "GET",
            url: rootURL + "/return_json/constants/roles",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then((resp) => {
            setRoles(JSON.parse(resp.data));
        });
    }, []);

    useEffect(() => {
        axios({
            method: "GET",
            url: rootURL + "/employees/not_working_staff",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then((resp) => {
            let payload = JSON.parse(resp.data);
            setNotWorkingStaff(payload);
        });
    }, [useReRender]);

    const [useLogsActiveUser, setLogsActiveUser] = useState(1);

    const handleModalContentAndOpen = (objectID) => {
        axios({
            method: "GET",
            url: rootURL + "/employees/" + objectID,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                let data = resp.data;
                setLogsActiveUser(data.id);
                return {
                    data: data,
                };
            })
            .then((ret) => {
                let parentID = ret.data.parent;
                axios({
                    method: "GET",
                    url: rootURL + "/employees/" + parentID + "/fullname",
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    },
                })
                    .then((resp) => {
                        let name = resp.data;
                        let retObject = ret.data;
                        retObject.parent = name;
                        return retObject;
                    })
                    .then((ret) => {
                        ret.role = useRoles[ret.role];
                        return ret;
                    })
                    .then(async (ret) => {
                        let department = await axios({
                            method: "GET",
                            url:
                                rootURL +
                                "/departments/" +
                                ret.department,
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        });
                        ret.department = department.data.label;
                        ret.quit = "No";
                        if (ret.has_leaved_company) {
                            ret.quit = "Yes";
                        }

                        let notes = await axios({
                            method: "GET",
                            url: rootURL + `/employees/${ret.id}/notes`,
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        });

                        let payload = await JSON.parse(notes.data);

                        ret.notes = await payload;

                        await handleModalContent(ret);
                        setLogsActiveUserName(ret.name + " " + ret.surname);
                        await handleModalToggle();
                    });
            });
    };

    const handleModalContent = (object) => {
        setModalContent(
            <TableContainer>
                <Table className={classes.table} aria-label="Employee Table">
                    <TableBody>
                        <TableRow>
                            <TableCell>Vorname: </TableCell>
                            <TableCell>{object.surname}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name: </TableCell>
                            <TableCell>{object.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Geburtsdatum: </TableCell>
                            <TableCell>{object.date_of_birth}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Vorgesetzer: </TableCell>
                            <TableCell>{object.parent}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Rolle: </TableCell>
                            <TableCell>{object.role}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Arbeitswochen: </TableCell>
                            <TableCell>{object.working_weeks}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Arbeitsverhältnis: </TableCell>
                            <TableCell>
                                {object.employment_relationship}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Beschäftigungsverhältnis: </TableCell>
                            <TableCell>{object.employment_type}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Abteilung: </TableCell>
                            <TableCell>{object.department}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Beschäftigungsverhältnis beendet?: </TableCell>
                            <TableCell>{object.quit}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Anmerkungen</TableCell>
                        </TableRow>
                        {object.notes.map((note) => {
                            return (
                                <TableRow>
                                    <TableCell>{note.date}</TableCell>
                                    <TableCell>{note.note}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    
    return (
        <>
            
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Widget
                        title="Nicht arbeitendes Personal"
                        upperTitle
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        button={
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={handleReRender}
                            >
                                Aktualisieren
                            </Button>
                        }
                    >
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-label="Mitarbeiter im Urlaub"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Arbeitswochen</TableCell>
                                        <TableCell>Urlaube</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {useNotWorkingStaff.map(
                                        (employee, employeeKey) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        {employeeKey + 1}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                handleModalContentAndOpen(
                                                                    employee.id
                                                                );
                                                            }}
                                                        >
                                                            {employee.fullname}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        {employee.working_weeks}
                                                    </TableCell>
                                                    <TableCell>{employee.holidays_raw}</TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Widget>
                </Grid>
            
            

            
        </>
    );
}
