// Cores
import React, { useState, useEffect, ReactDOM } from "react";
import axios from "axios";

// Components
import { Grid, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ExpandIcon,
} from "../../components/Accordion/Accordion";
import MUIDataTable from "mui-datatables";
import TextField from "@material-ui/core/TextField";
import notification from "../../helpers/SnackbarUtils";

/* Modal */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Helpers
import { processTableDataForDepartments, confirm, renderIf } from "../../helpers/helper";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/css/custom.css";

const useStyles = makeStyles((theme) => ({
    form: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
}));

const ApiURL = process.env.REACT_APP_ROOT;

export default function Employee(props) {
    const classes = useStyles();

    // States
    const [useExpanded, setExpanded] = useState("panel1");
    const [useDepartments, setDepartments] = useState([]);
    const [useDepartmentReRender, setDepartmentReRender] = useState(0.1);

    // Handles
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleDeleteButtonClick = (id) => {
        confirm(function () {
            axios({
                method: "DELETE",
                url: ApiURL + "/departments/" + id,
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            })
                .then((resp) => {
                    let payload = resp.data;
                    notification.success(payload);
                })
                .then(() => {
                    setDepartmentReRender(Math.random());
                });
        });
    };

    const [useLabel, setLabel] = useState("");
    const handleLabelChange = (event) => {
        setLabel(event.target.value);
    };

    const handleCreate = (event) => {
        event.preventDefault();
        
        notification.info("Abteilung erstellen...");
        axios({
            method: "POST",
            url: ApiURL + "/departments",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: {
                label: useLabel,
            },
        })
            .then((resp) => {
                let payload = resp.data;
                notification.success(payload.label + " abteilung hinzugefügt");
            })
            .then(() => {
                setDepartmentReRender(Math.random());
            });
        //return false;
    };

    // Variables
    const [useDataTableData, setDataTableData] = useState([]);
    useEffect(() => {
        const datatableData = processTableDataForDepartments(
            useDepartments,
            true,
            handleEditButtonClick,
            handleDeleteButtonClick,
            () => {},
            () => {},
            () => {},
            props.isAdmin,
        );
        // console.log("datatableData", datatableData);
        setDataTableData(datatableData);
    }, [useDepartments]);

    // Effects
    useEffect(() => {
        axios({
            method: "GET",
            url: ApiURL + "/departments?format=1",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                // console.log("resp", resp);
                let payload = resp.data;
                // console.log("departmenmts", payload);
                setDepartments(payload);
                
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [useDepartmentReRender]);

    // Edit Dialog
    const [useEditId, setEditId] = useState(0);
    const [useEditLabel, setEditLabel] = useState("");
    const [useModalOpen, setModalOpen] = useState(false);

    const handleEditButtonClick = (id) => {
        axios({
            method: "GET",
            url: ApiURL + `/departments/${id}`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                setEditId(id);
                let payload = resp.data;
                setEditLabel(payload.label);
            })
            .then(() => {
                setModalOpen(true);
            });
    };

    const handleLabelEditChange = (event) => {
        setEditLabel(event.target.value);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleFormSend = () => {
        //console.log("sth - " + useEditId);
        axios({
            method: "PUT",
            url: ApiURL + `/departments/${useEditId}`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: {
                label: useEditLabel,
            },
        })
            .then((resp) => {
                let payload = resp.data;
                //console.log(resp);
                notification.success(payload.label + " bearbeiten erfolgreich!");
            })
            .then(() => {
                setModalOpen(false);
            })
            .then(() => {
                setDepartmentReRender(Math.random());
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    //ReactDOM.findDOMNode("OrganizationChart").onClick


    return (
        <>
            {renderIf(
                props.isAdmin,
                <Accordion
                    square
                    expanded={useExpanded === "panel1"}
                    onChange={handleChange("panel1")}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ExpandIcon />}
                    >
                        <Typography>Abteilung hinzufügen</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form
                            onSubmit={handleCreate}
                            className={classes.form}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                onChange={handleLabelChange}
                                id="label"
                                label="Titel"
                                required
                            />
                            <br />
                            <Button
                                variant="contained"
                                size="medium"
                                color="secondary"
                                type="submit"
                            >
                                Speichern
                            </Button>
                        </form>
                    </AccordionDetails>
                </Accordion>
            )}
            <Accordion
                square
                expanded={useExpanded === "panel2"}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    expandIcon={<ExpandIcon />}
                >
                    <Typography>Abteilungen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title="Abteilungsliste"
                            data={useDataTableData}
                            columns={["ID", "Name", "Verfahren"]}
                            options={{
                                filterType: "checkbox",
                                rowsPerPage: 10,
                                rowsPerPageOptions: [
                                    5,
                                    10,
                                    useDataTableData.length,
                                ],
                                print: false,
                                fixedHeader: true,
                            }}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Dialog
                open={useModalOpen}
                onClose={handleModalClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Bearbeiten {useEditLabel}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        defaultValue={useEditLabel}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Titel"
                        type="text"
                        fullWidth
                        onChange={handleLabelEditChange}
                        value={useEditLabel}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleFormSend} color="primary">
                        Senden
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
