import React, { useState, useEffect } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    InputBase,
    Menu,
    MenuItem,
    Fab,
    Link,
} from "@material-ui/core";
import {
    Menu as MenuIcon,
    MailOutline as MailIcon,
    NotificationsNone as NotificationsIcon,
    Person as AccountIcon,
    Search as SearchIcon,
    Send as SendIcon,
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import  { Redirect } from 'react-router-dom'

// logo
import otto from "../../images/otto.png";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import axios from "axios";

const ApiURL = process.env.REACT_APP_ROOT;

export default function Header(props) {
    var classes = useStyles();

    // global
    var layoutState = useLayoutState();
    var layoutDispatch = useLayoutDispatch();
    var userDispatch = useUserDispatch();

    // local
    var [mailMenu, setMailMenu] = useState(null);
    var [isMailsUnread, setIsMailsUnread] = useState(true);
    var [notificationsMenu, setNotificationsMenu] = useState(null);
    var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
    var [profileMenu, setProfileMenu] = useState(null);
    var [isSearchOpen, setSearchOpen] = useState(false);

    const [useAuthorizedUser, setAuthorizedUser] = useState({});
    useEffect(() => {
        axios({
            method: "GET",
            url: ApiURL + `/get_current_user`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then(resp => {
            let payload = JSON.parse(resp.data);
            setAuthorizedUser(payload)
        });
    }, [props.token]);

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(layoutDispatch)}
                    className={classNames(
                        classes.headerMenuButtonSandwich,
                        classes.headerMenuButtonCollapse
                    )}
                >
                    {layoutState.isSidebarOpened ? (
                        <ArrowBackIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse
                                ),
                            }}
                        />
                    ) : (
                        <MenuIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse
                                ),
                            }}
                        />
                    )}
                </IconButton>
                <Typography
                    variant="h6"
                    weight="medium"
                    className={classes.ottotype}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <img
                        src={otto}
                        style={{ height: "1.7rem", width: "auto" }}
                    />
                    <span style={{ marginLeft: "8px" }}>
                        PD Group - Personalmatrix
                    </span>
                </Typography>
                <div className={classes.grow} />

                

                <IconButton
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.headerMenuButton}
                    aria-controls="profile-menu"
                    onClick={(e) => setProfileMenu(e.currentTarget)}
                >
                    <AccountIcon classes={{ root: classes.headerIcon }} />
                </IconButton>

                <Menu
                    id="profile-menu"
                    open={Boolean(profileMenu)}
                    anchorEl={profileMenu}
                    onClose={() => setProfileMenu(null)}
                    className={classes.headerMenu}
                    classes={{ paper: classes.profileMenu }}
                    disableAutoFocusItem
                >
                    <div className={classes.profileMenuUser}>
                        <Typography variant="h4" weight="medium">
                            {useAuthorizedUser.name}
                        </Typography>
                    </div>
                    
                    {/* <MenuItem
                        component={Link}
                        to="/app/settings"
                        className={classNames(
                            classes.profileMenuItem,
                            classes.headerMenuItem
                        )}
                    >
                        <AccountIcon className={classes.profileMenuIcon} />{" "}
                        Profil
                    </MenuItem> */}
                    
                    <div className={classes.profileMenuUser}>
                        <Typography
                            className={classes.profileMenuLink}
                            color="primary"
                            onClick={() => signOut(userDispatch, props.history)}
                        >
                            Abmelden
                        </Typography>
                    </div>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}
