import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider, useSnackbar } from "notistack";

// add a <div> child to body under which to mount the snackbars
const mountPoint = document.createElement("div");
document.body.appendChild(mountPoint);

let notifications = [];

const toast = {
    success: function (msg, position = {horizontal: "right", vertical: "top"}) {
        this.toast(msg, "success", position);
    },
    warning: function (msg, position = {horizontal: "right", vertical: "top"}) {
        this.toast(msg, "warning", position);
    },
    info: function (msg, position = {horizontal: "right", vertical: "top"}) {
        this.toast(msg, "info", position);
    },
    error: function (msg, position = {horizontal: "right", vertical: "top"}) {
        this.toast(msg, "error", position);
    },
    toast: function (msg, variant = "default", position = {horizontal: "right", vertical: "top"}) {

        const ShowSnackbar = ({ message }) => {
            const { enqueueSnackbar } = useSnackbar();
            enqueueSnackbar(message, { variant });
            return null;
        };

        const closeSnack = (key) => {
            setTimeout(() => {
                return notifications.current.closeSnackbar(key);
            }, 3000);
        }

        ReactDOM.render(
            // see https://github.com/iamhosseindhv/notistack#snackbarprovider
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={position}
                ref={notifications}
                action={(key) => {closeSnack(key)}}            
            >
                <ShowSnackbar message={msg} variant={variant} />
            </SnackbarProvider>,
            mountPoint
        );
    },
};

export default toast;
