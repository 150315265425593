import React, { useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

// context
import { useUserState } from "../context/UserContext";

const ApiURL = process.env.REACT_APP_ROOT;

export default function App(props) {
    // global
    //var { isAuthenticated } = useUserState();
    
    const [useIsAuthenticated, setIsAuthenticated] = useState(false);
    const [useToken, setToken] = useState(localStorage.getItem("token"));
    if(useToken !== null){
        axios({
            method: "GET",
            url: ApiURL + "/is_authenticated",
            headers: {
                Authorization: `Bearer ${useToken}`,
            },
        }).then(resp => {   
            setIsAuthenticated(true);
        }).catch(function(error){
            setIsAuthenticated(false);
        });
    }

    return (
        <HashRouter>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/app/dashboard" />}
                />
                <Route
                    exact
                    path="/app"
                    render={() => <Redirect to="/app/dashboard" />}
                />
                <PrivateRoute path="/app" component={() => <Layout hasSeen={props.hasSeen} setHasSeen={props.setHasSeen} token={useToken} />} />
                <PublicRoute path="/login" component={Login} />
                <Route component={Error} />
            </Switch>
        </HashRouter>
    );

    // #######################################################################

    function PrivateRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={(props) =>
                    useIsAuthenticated ? (
                        React.createElement(component, props)
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    )
                }
            />
        );
    }

    function PublicRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={(props) =>
                    useIsAuthenticated ? (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    ) : (
                        React.createElement(component, props)
                    )
                }
            />
        );
    }
}
