import React, { useState } from "react";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "../assets/css/react-confitm.css";

// Icons
import Create from "@material-ui/icons/Create";
import Delete from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CommentIcon from "@material-ui/icons/Comment";
import EventIcon from "@material-ui/icons/Event";

const processTableData = (
    tableData,
    showView = true,
    onEditHandle = () => {},
    onDeleteHandle = () => {},
    onViewHandle = () => {},
    onNoteHandle = () => {},
    onHolidayHandle = () => {},
    isAdmin = false,
    extraData = [],
) => {
    const renderIfIsAdmin = (component) => {
        return renderIf(isAdmin, component);
    };

    const buttons = (id) => {
        let show = "initial";
        if (!showView) {
            show = "none";
        }
        return (
            <>
                {renderIfIsAdmin(
                    <div
                        className="btn-object"
                        onClick={() => onEditHandle(id)}
                        title="Edit"
                    >
                        <Create />
                    </div>
                )}
                {renderIfIsAdmin(
                <div
                    className="btn-object"
                    onClick={() => onDeleteHandle(id)}
                    title="Delete"
                >
                    <Delete />
                </div>
                )}
                <div
                    className="btn-object"
                    onClick={() => onViewHandle(id)}
                    title="View"
                    style={{ display: show }}
                >
                    <VisibilityIcon />
                </div>
                <div
                    className="btn-object"
                    onClick={() => onNoteHandle(id)}
                    title="Notes"
                    style={{ display: show }}
                >
                    <CommentIcon />
                </div>
                <div
                    className="btn-object"
                    onClick={() => onHolidayHandle(id)}
                    title="Holidays"
                    style={{ display: show }}
                >
                    <EventIcon />
                </div>
            </>
        );
    };

    const newArr = [];
    tableData.forEach((data, dataIndex) => {
        let id = data[0];
        data.shift();
        const newData = data;
        newData.push(extraData[dataIndex]);
        newData.push(() => buttons(id));
        newArr.push(newData);
    });
    return newArr;
};

const processTableDataForDepartments = (
    tableData,
    showView = true,
    onEditHandle = () => {},
    onDeleteHandle = () => {},
    onViewHandle = () => {},
    onNoteHandle = () => {},
    onHolidayHandle = () => {},
    isAdmin = false,
) => {
    const renderIfIsAdmin = (component) => {
        return renderIf(isAdmin, component);
    };

    const buttons = (id) => {
        let show = "initial";
        if (!showView) {
            show = "none";
        }
        return (
            <>
                {renderIfIsAdmin(
                    <div
                        className="btn-object"
                        onClick={() => onEditHandle(id)}
                        title="Edit"
                    >
                        <Create />
                    </div>
                )}
                {renderIfIsAdmin(
                <div
                    className="btn-object"
                    onClick={() => onDeleteHandle(id)}
                    title="Delete"
                >
                    <Delete />
                </div>
                )}
            </>
        );
    };

    const newArr = [];
    tableData.forEach((data, dataIndex) => {
        let id = data[0];
        data.shift();
        const newData = data;
        newData.push(() => buttons(id));
        newArr.push(newData);
    });
    return newArr;
};

const confirm = (
    callback = () => {},
    options = {
        title: "Confirm to submit",
        message: "Are you sure to process this action?",
    }
) => {
    if (options.title === undefined) {
        options.title = "Confirm to submit";
    }

    if (options.message === undefined) {
        options.message = "Are you sure to process this action?";
    }

    confirmAlert({
        title: options.title,
        message: options.message,
        buttons: [
            {
                label: "Yes",
                onClick: () => callback(),
            },
            {
                label: "No",
                onClick: () => console.log("cancelled"),
            },
        ],
    });
};

function renderIf(variable, component) {
    if (variable) {
        return <div style={{ display: "initial" }}>{component}</div>;
    } else {
        return <div style={{ display: "none" }}>{component}</div>;
    }
}

export { processTableData, processTableDataForDepartments, confirm, renderIf };
