import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";

const hasSeenStorage = localStorage.getItem("hasSeen");
let today = new Date();
today.setHours(0,0,0,0);
const hasSeen = !(
    !hasSeenStorage ||
    new Date(hasSeenStorage) <= today
);

const setHasSeen = () => localStorage.setItem("hasSeen", new Date());

// if(!hasSeen) {
//     localStorage.setItem("hasSeen", new Date());
// }

ReactDOM.render(
    <LayoutProvider>
        <UserProvider>
            <ThemeProvider theme={Themes.default}>
                <CssBaseline />
                <App hasSeen={hasSeen} setHasSeen={setHasSeen} />
            </ThemeProvider>
        </UserProvider>
    </LayoutProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
