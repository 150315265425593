import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import notification from "../../helpers/SnackbarUtils"
import axios from "axios";

export default function MultiNote(props) {
    const ApiURL = process.env.REACT_APP_ROOT;

    //const employeeId = useRef(props.itemId);
    const [useReRenderAllNotes, setReRenderAllNotes] = useState(Math.random());

    const [useMultiNoteModalOpen, setMultiNoteModalOpen] = useState(true);

    useEffect(() => {
        setMultiNoteModalOpen(!useMultiNoteModalOpen);
    }, [props.open]);

    const handleMultiNoteModalToggle = () => {
        setMultiNoteModalOpen(!useMultiNoteModalOpen);
    };

    const [useAllNotesData, setAllNotesData] = useState([]);

    useEffect(() => {
        if(props.isMounted){
        let itemId = props.itemId;
            axios({
                method: "GET",
                url: ApiURL + `/employee_multi_notes/by_employee/${itemId}`,
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            }).then((resp) => {
                let payload = resp.data;
                let res = [];
                payload.forEach((row) => {
                    res.push({
                        key: row.key,
                        value: row.value,
                    });
                });
                setAllNotesData(res);
            });
        }
    }, [props.itemId, useReRenderAllNotes, props.isMounted]);

    // Add new
    const [useInputKey, setInputKey] = useState("");
    const [useInputValue, setInputValue] = useState("");
    const handleInputKeyChange = (event) => {
        setInputKey(event.target.value);
    };
    const handleInputValueChange = (event) => {
        setInputValue(event.target.value);
    };
    const handleInputsReset = () =>{
        setInputKey("");
        setInputValue("");
    }

    const handleNoteSave = (event) => {
        let data = {
            employee_id: props.itemId,
            input_type: "text",
            key: useInputKey,
            value: useInputValue,
        };
        axios({
            method: "POST",
            url: ApiURL + `/employee_multi_notes`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: data,
        }).then((resp) => {
            notification.success("Notiz erfolgreich gespeichert");
        }).then(() => {
            handleInputsReset();
        }).then(() => {
            setReRenderAllNotes(Math.random())
        });

        event.preventDefault();
    };

    return (
        <Dialog
            open={useMultiNoteModalOpen}
            onClose={handleMultiNoteModalToggle}
            aria-labelledby="form-multinote-dialog"
        >
            <DialogTitle id="form-multinote-dialog">{props.title}</DialogTitle>
            <DialogContent>
                <TableContainer>
                    <form onSubmit={handleNoteSave}>
                        <Table>
                            <TableBody>
                                {useAllNotesData.length > 0 &&
                                    useAllNotesData.map((element, i) => (
                                        <TableRow>
                                            <TableCell>{element.key}</TableCell>
                                            <TableCell>
                                                {element.value}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {props.modifier && (
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                id="key"
                                                label="Titel"
                                                required
                                                value={useInputKey}
                                                onChange={handleInputKeyChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                id="value"
                                                label="Erläuterung"
                                                required
                                                value={useInputValue}
                                                onChange={
                                                    handleInputValueChange
                                                }
                                            />
                                        </TableCell>
                                        <TableCell style={{ padding: "5px" }}>
                                            <Button
                                                variant="outlined"
                                                size="medium"
                                                style={{
                                                    border: "2px solid #15CF15",
                                                    padding: "5px",
                                                    color: "#048504",
                                                }}
                                                type="submit"
                                            >
                                                Speichern
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </form>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleMultiNoteModalToggle} color="primary">
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
