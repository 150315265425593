import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ExpandIcon,
} from "../Accordion/Accordion";
import Typography from "@material-ui/core/Typography";
import {
    Grid,
    Button,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

const roles = [
    "Mitarbeiter",
    "Vorarbeiter",
    "Meister",
    "Abteilungsleiter",
    "Produktionsleiter",
];

export default function Exports(props) {
    const ApiURL = process.env.REACT_APP_ROOT;

    // Accordion
    const [useAccordionExpanded, setAccordionExpanded] = useState(false);
    const handleAccordion = (panel) => (event, newExpanded) => {
        setAccordionExpanded(newExpanded ? panel : false);
    };

    const [useDataTableData, setDataTableData] = useState([]);

    useEffect(() => {
        axios({
            method: "get",
            url: ApiURL + "/import_export/exportedFiles",
            headers: {
                Authorization: "Bearer " + props.token,
            },
        }).then((response) => {
            const resultJSON = JSON.parse(response.data);
            console.log(resultJSON);
            return resultJSON;
        }).then(response => {
            response.map(item => {
                item['Name'] = item['name'];
                item['Datum'] = item['date'];
                item['Herunterladen'] = (
                    <>
                    <Button
                        variant="contained"
                        color="primary"
                        href={`${item['url']}`}
                        target="_blank"
                    >
                        Herunterladen
                    </Button>
                    <Button style={{marginLeft: "10px"}} variant="contained" color="secondary" onClick={() => deleteFile(item['Name'])}>
                        Löschen
                    </Button>
                    </>
                );
                delete item['name'];
                delete item['date'];
                delete item['url'];
            });
            setDataTableData(response);
        });
    }, []);

    const [useDialogOpen, setDialogOpen] = useState(false);
    const [useWillBeDeletedFile, setWillBeDeletedFile] = useState('');
    const [useAcceptDeletion, setAcceptDeletion] = useState(false);
    const closeDialog = () => setDialogOpen(false);
    const deleteFile = (name) => {
        setWillBeDeletedFile(name);
        setDialogOpen(true);
    };
    const acceptDeletion = (name) => {
        setAcceptDeletion(true);
        closeDialog();
        axios({
            method: "delete",
            url: `${ApiURL}/import_export/exportedFiles/${name}`,
            headers: {
                Authorization: "Bearer " + props.token,
            },
        }).then((response) => {
            console.log(response);
            window.location.reload();
        });
    };
    
    return (
        <div className="exports-container">
            <Accordion
                square
                expanded={useAccordionExpanded === "exports-panel-1"}
                onChange={handleAccordion("exports-panel-1")}
            >
                <AccordionSummary
                    aria-controls="exports-panel-1-content"
                    id="exports-panel-1-header"
                    expanded={{}}
                    expandIcon={<ExpandIcon />}
                >
                    <Typography>Exportierte XLSs</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title="Exportierte XLSs"
                            data={useDataTableData}
                            columns={["Name", "Datum", "Herunterladen"]}
                            options={{
                                filterType: "checkbox",
                                rowsPerPage: 10,
                                rowsPerPageOptions: [
                                    5,
                                    10,
                                    useDataTableData.length,
                                ],
                                print: false,
                                fixedHeader: true,
                            }}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Dialog
                open={useDialogOpen}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Exportierte Datei löschen?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Die exportierte Datei {useWillBeDeletedFile} wird gelöscht.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Stornieren</Button>
                    <Button onClick={() => acceptDeletion(useWillBeDeletedFile)} autoFocus>Zustimmen</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
