import React, {useState, useEffect} from "react";
import { Grid, Button, TextField, Table, TableContainer, TableBody, TableRow, TableCell } from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import notification from "../../helpers/SnackbarUtils"

import axios from "axios";

import useStyles from "./styles";

const ApiURL = process.env.REACT_APP_ROOT;

export default function Settings(props){
    var classes = useStyles();

    //Fields
    const [useOldPassword, setOldPassword] = useState("");
    const [useNewPassword, setNewPassword] = useState("");
    const [useNewPasswordRepeat, setNewPasswordRepeat] = useState("");

    //handles
    const handleOldPassword = (event) => { setOldPassword(event.target.value) }
    const handleNewPassword = (event) => { setNewPassword(event.target.value) }
    const handleNewPasswordRepeat = (event) => { setNewPasswordRepeat(event.target.value) }
    

    const [useAuthorizedUser, setAuthorizedUser] = useState({});
    const axiosHeader = {
        Authorization: `Bearer ${props.token}`,
    };

    

    const handleFormSubmit = (event) => {
        function passwordFieldsReset(){
            setOldPassword('');
            setNewPassword('');
            setNewPasswordRepeat('');
        }
        if(useOldPassword != "" || useNewPassword != "" || useNewPasswordRepeat != ""){
            if(useOldPassword != ""){
                axios({
                    method: "POST",
                    url: ApiURL + `/users/check_password`,
                    headers: axiosHeader,
                    data: {
                        password: useOldPassword
                    }
                }).then(resp => {
                    axios({
                        method: "POST",
                        url: ApiURL + `/users/change_password`,
                        headers: axiosHeader,
                        data: {
                            password: useOldPassword,
                            new_password: useNewPassword
                        }
                    }).then(resp => {
                        console.log(resp);
                        notification.success('Das Passwort wurde erfolgreich geändert!');
                        passwordFieldsReset();
                    }).catch(error => {
                        notification.error('Altes Passwort ist nicht korrekt!');
                    });
                }).catch(error => {
                    notification.error('Altes Passwort ist nicht korrekt!');
                    console.log(error);
                });
            } else if(useNewPassword != useNewPasswordRepeat || useNewPassword == ""){
                notification.error("Neues Passwort und Passwortwiederholung stimmen nicht überein.");
            }
        }

        

        event.preventDefault();
    }

    return (
        <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <form onSubmit={handleFormSubmit}>
                    <Widget
                        title={"Profileinstellungen ("+useAuthorizedUser.username+")"}
                        upperTitle
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    
                                    <TableRow>
                                        <TableCell>
                                            <TextField 
                                                id="old-passoword"
                                                label="Altes Passwort"
                                                value={useOldPassword}
                                                style={{width: "100%"}}
                                                onChange={handleOldPassword}
                                                type="password"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField 
                                                id="new-password"
                                                label="Neues Passwort"
                                                value={useNewPassword}
                                                style={{width: "100%"}}
                                                onChange={handleNewPassword}
                                                type="password"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField 
                                                id="new-password-repeat"
                                                label="Neues Passwort(Gleich)"
                                                value={useNewPasswordRepeat}
                                                style={{width: "100%"}}
                                                onChange={handleNewPasswordRepeat}
                                                type="password"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <Button type="submit" variant="contained" color="primary">Speichern</Button>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        
                    </Widget>
                </form>
            </Grid>
        </>
    );
}