// Cores
import React, { useState, useEffect } from "react";
import {Redirect} from "react-router-dom";
import axios from "axios";

// Components
import { Grid, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ExpandIcon,
} from "../../components/Accordion/Accordion";
import MUIDataTable from "mui-datatables";
import TextField from "@material-ui/core/TextField";
import notification from "../../helpers/SnackbarUtils";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

/* Modal */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Helpers
import { processTableData, confirm } from "../../helpers/helper";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/css/custom.css";

const useStyles = makeStyles((theme) => ({
    form: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
}));

const ApiURL = process.env.REACT_APP_ROOT;
const userTypes = {
    "-1": "Modifier",
    1: "Viewer",
};

export default function User(props) {

   

    const classes = useStyles();

    // States
    const [useExpanded, setExpanded] = useState("panel1");
    const [useObjects, setObjects] = useState([]);
    const [useReRender, setReRender] = useState(0.1);

    const [useName, setName] = useState("");
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const [useEditName, setEditName] = useState("");
    const handleNameEditChange = (event) => {
        setEditName(event.target.value);
    };

    const [useEmail, setEmail] = useState("");
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const [useEditEmail, setEditEmail] = useState("");
    const handleEmailEditChange = (event) => {
        setEditEmail(event.target.value);
    };

    const [useUsername, setUsername] = useState("");
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };
    const [useEditUsername, setEditUsername] = useState("");
    const handleUsernameEditChange = (event) => {
        setEditUsername(event.target.value);
    };

    const [useUserType, setUserType] = useState(1);
    const handleUserTypeChange = (event) => {
        setUserType(event.target.value);
    };
    const [useEditUserType, setEditUserType] = useState(1);
    const handleUserTypeEditChange = (event) => {
        setEditUserType(event.target.value);
    };

    // Handles
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleDeleteButtonClick = (id) => {
        confirm(function () {
            axios({
                method: "DELETE",
                url: ApiURL + "/users/" + id,
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            })
                .then((resp) => {
                    let payload = resp.data;
                    notification.success(payload);
                })
                .then(() => {
                    setReRender(Math.random());
                });
        });
    };

    const handleCreate = (event) => {
        event.preventDefault();
        notification.info("Benutzer wird erstellt...");
        axios({
            method: "POST",
            url: ApiURL + "/register",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: {
                name: useName,
                username: useUsername,
                user_type: useUserType,
                email: useEmail,
                password: "12345678",
                password_confirmation: "12345678"
            },
        })
            .then((resp) => {
                let payload = resp.data;
                notification.success("Benutzer wird hinzugefügt. Sein Passwort lautet `12345678`. Sie können dieses Passwort auf der Profilseite ändern.");
            })
            .then(() => {
                setReRender(Math.random());
            });
    };

    // Variables
    const [useDataTableData, setDataTableData] = useState([]);

    useEffect(() => {
        const datatableData = processTableData(
            useObjects,
            false,
            handleEditButtonClick,
            handleDeleteButtonClick,
            () => {}
        );
        setDataTableData(datatableData);
    }, [useObjects]);

    // Effects
    useEffect(() => {
        axios({
            method: "GET",
            url: ApiURL + "/users?format=1",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                setObjects(resp.data);
                //console.log(resp.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [useReRender]);

    // Edit Dialog
    const [useEditId, setEditId] = useState(0);
    const [useModalOpen, setModalOpen] = useState(false);

    const handleEditButtonClick = (id) => {
        axios({
            method: "GET",
            url: ApiURL + `/users/${id}`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                setEditId(id);
                let payload = resp.data;
                setEditName(payload.name);
                setEditEmail(payload.email);
                setEditUserType(payload.user_type);
                setEditUsername(payload.username);
            })
            .then(() => {
                setModalOpen(true);
            });
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleFormSend = () => {
        //console.log("sth - " + useEditId);
        axios({
            method: "PUT",
            url: ApiURL + `/users/${useEditId}`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: {
                name: useEditName,
                email: useEditEmail,
                user_type: useEditUserType,
                username: useEditUsername,
            },
        })
            .then((resp) => {
                let payload = resp.data;
                //console.log(resp);
                notification.success(payload.name + " bearbeiten erfolgreich!");
            })
            .then(() => {
                setModalOpen(false);
            })
            .then(() => {
                setReRender(Math.random());
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <>
            <Accordion
                square
                expanded={useExpanded === "panel1"}
                onChange={handleChange("panel1")}
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ExpandIcon />}
                >
                    <Typography>Nutzer hinzufügen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <form
                        onSubmit={handleCreate}
                        className={classes.form}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            onChange={handleNameChange}
                            id="name"
                            label="Name"
                            required
                        />
                        <br />
                        <TextField
                            onChange={handleEmailChange}
                            id="email"
                            label="Email"
                            required
                        />
                        <br />
                        <TextField
                            onChange={handleUsernameChange}
                            id="username"
                            label="Nutzername"
                            required
                        />
                        <br />
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="edit-user_type-label">
                                Benutzertyp
                            </InputLabel>
                            <Select
                                labelId="edit-user_type-label"
                                id="userType"
                                value={useUserType}
                                onChange={handleUserTypeChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                required
                            >
                                <MenuItem value={-1}>Modifikator</MenuItem>
                                <MenuItem value={1}>Zuschauer</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            type="submit"
                        >
                            Speichern
                        </Button>
                    </form>
                </AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={useExpanded === "panel2"}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    expandIcon={<ExpandIcon />}
                >
                    <Typography>Benutzer</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title="User List"
                            data={useDataTableData}
                            columns={["Nutzername", "Name", "Email", "Verfahren"]}
                            options={{
                                filterType: "checkbox",
                                rowsPerPage: 10,
                                rowsPerPageOptions: [
                                    5,
                                    10,
                                    useDataTableData.length,
                                ],
                                print: false,
                                fixedHeader: true,
                            }}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Dialog
                open={useModalOpen}
                onClose={handleModalClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Bearbeiten {useEditName}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        defaultValue={useEditName}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        onChange={handleNameEditChange}
                        value={useEditName}
                    />
                    <TextField
                        defaultValue={useEditEmail}
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email"
                        type="text"
                        fullWidth
                        onChange={handleEmailEditChange}
                        value={useEditEmail}
                    />
                    <TextField
                        defaultValue={useEditUsername}
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Nutzername"
                        type="text"
                        fullWidth
                        onChange={handleUsernameEditChange}
                        value={useEditUsername}
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink id="edit-user_type-label">
                            Benutzertyp
                        </InputLabel>
                        <Select
                            labelId="edit-user_type-label"
                            id="userType"
                            value={useEditUserType}
                            onChange={handleUserTypeEditChange}
                            displayEmpty
                            className={classes.selectEmpty}
                            required
                        >
                            <MenuItem value={-1}>Modifikator</MenuItem>
                            <MenuItem value={1}>Zuschauer</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleFormSend} color="primary">
                        Senden
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
