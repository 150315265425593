
// Pages
import Dashboard from "./pages/dashboard";
import Employee from "./pages/employee";
import Department from "./pages/department";
import User from "./pages/users/User";
import Settings from "./pages/settings/Settings";
import NotWorking from "./pages/not_working/NotWorking";

import Typography from "./pages/typography";
import Tables from "./pages/tables";
import Maps from "./pages/maps";
import Icons from "./pages/icons";
import Charts from "./pages/charts";

// Icons
import {
    Home as HomeIcon,
    NotificationsNone as NotificationsIcon,
    FormatSize as TypographyIcon,
    FilterNone as UIElementsIcon,
    BorderAll as TableIcon,
    QuestionAnswer as SupportIcon,
    LibraryBooks as LibraryIcon,
    HelpOutline as FAQIcon,
    ArrowBack as ArrowBackIcon,
    Group as GroupIcon,
    Business as BusinessIcon,
    Person as PersonIcon,
    PersonAddDisabled
} from "@material-ui/icons";

import SettingsIcon from '@material-ui/icons/Settings';

const modifierUrls = [
    "Users",
    "Settings"
];

const Urls = [
    {
        title: "Organigramm",
        path: "/app/dashboard",
        component: Dashboard,
        icon: <HomeIcon />,
        isAdminUrl: false,
    },
    {
        title: "Arbeitnehmer",
        path: "/app/employee",
        component: Employee,
        icon: <GroupIcon />,
        isAdminUrl: false,
    },
    {
        title: "Arbeiter im Urlaub",
        path: "/app/not_working",
        component: NotWorking,
        icon: <PersonAddDisabled />,
        isAdminUrl: false,
    },
    {
        title: "Abteilungen",
        path: "/app/departments",
        component: Department,
        icon: <BusinessIcon />,
        isAdminUrl: false,
    },
    {
        title: "Benutzer",
        path: "/app/users",
        component: User,
        icon: <PersonIcon />,
        isAdminUrl: true,
    },
    {
        title: "Einstellungen",
        path: "/app/settings",
        component: Settings,
        icon: <SettingsIcon />,
        isAdminUrl: true,
    }
    /*,
    {
        title: "Typography",
        path: "/app/typography",
        component: Typography,
        icon: <TypographyIcon />
    },
    {
        title: "Tables",
        path: "/app/tables",
        component: Tables,
        icon: <TableIcon />
    }
    */
];


export default Urls;