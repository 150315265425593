// Cores
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { renderIf } from "../../helpers/helper";

// Components
import { Grid, Button, Input } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ExpandIcon,
} from "../../components/Accordion/Accordion";
import MUIDataTable from "mui-datatables";
import TextField from "@material-ui/core/TextField";
import notification from "../../helpers/SnackbarUtils";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { ListSubheader } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import DeleteIcon from "@material-ui/icons/Delete";

/* Modal */
// !
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
} from "@material-ui/core";
// !

// Helpers
import { processTableData, confirm } from "../../helpers/helper";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/css/custom.css";
import { mdiConsoleLine } from "@mdi/js";

const useStyles = makeStyles((theme) => ({
    form: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const ApiURL = process.env.REACT_APP_ROOT;

export default function Employee(props) {
    const renderIfIsAdmin = (component) => {
        renderIf(props.isAdmin, component);
    };

    const classes = useStyles();

    // States
    const [useExpanded, setExpanded] = useState("panel1");
    const [useReRender, setReRender] = useState(0.1);
    const [useAllEmployees, setAllEmployees] = useState([]);

    // Handles
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    // Edit Dialog

    const [useModalOpen, setModalOpen] = useState(false);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    // Name
    const [useName, setName] = useState("");
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    // Surname
    const [useSurname, setSurname] = useState("");
    const handleSurnameChange = (event) => {
        setSurname(event.target.value);
    };

    // Date of birth
    const eighteenYearsAgo = moment().subtract("18", "y").format("DD-MM-yyyy");
    const [useDateOfBirth, setDateOfBirth] = useState(eighteenYearsAgo);
    const handleDateOfBirthChange = (event) => {
        setDateOfBirth(event.target.value);
    };

    // Role
    const [useRole, setRole] = useState(0);
    const [useRoleConstants, setRoleConstants] = useState([]);
    useEffect(() => {
        axios({
            method: "GET",
            url: ApiURL + `/return_json/constants/roles`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then((resp) => {
            let resultJSON = JSON.parse(resp.data);
            setRoleConstants(resultJSON);
            return resultJSON;
        });
    }, []);
    const handleRoleSelect = (event) => {
        setRole(event.target.value);
    };

    // Working weeks
    const [useWorkingWeeks, setWorkingWeeks] = useState("1-52");
    const handleWorkingWeeksChange = (event) => {
        setWorkingWeeks(event.target.value);
    };

    // Employment Relationship
    const [useEmploymentRelationship, setEmploymentRelationship] = useState("Mitarbeiter");
    const handleEmploymentRelationshipChange = (event) => {
        setEmploymentRelationship(event.target.value);
        console.log(event.target.value);
    };

    // Employment Type
    const [useEmploymentType, setEmploymentType] = useState("");
    const handleEmploymentTypeChange = (event) => {
        setEmploymentType(event.target.value);
    };

    // Role
    const [useDepartment, setDepartment] = useState(0);
    const [useDepartments, setDepartments] = useState([]);
    const [useOriginalDepartments, setOriginalDepartments] = useState([]);
    const [useDepartmentSearch, setDepartmentSearch] = useState("");
    useEffect(() => {
        if(useDepartmentSearch == "") {
            setDepartments(useOriginalDepartments);
        } else {
            setDepartments(
                useOriginalDepartments.filter(department => 
                    department[1].toLowerCase().indexOf(useDepartmentSearch.toLowerCase()) > -1
                )
            );
        }
    }, [useDepartmentSearch]);
    useEffect(() => {
        axios({
            method: "GET",
            url: ApiURL + `/departments?format=1`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                let resultJSON = resp.data;
                setDepartments(resultJSON);
                setOriginalDepartments(resultJSON);
                console.log(resultJSON);
                return resultJSON;
            })
            .then((resp) => {
                
            });
    }, []);
    const handleDepartmentSelect = (event) => {
        setDepartment(event.target.value);
    };

    // Parent
    const [useParent, setParent] = useState(0);
    const [useParentTrees, setParentTrees] = useState([]);
    const [useJustNames, setJustNames] = useState([]);
    const [useParentSearch, setParentSearch] = useState("");
    useEffect(() => {
        if(useParentSearch == ""){
            setNewParentTree(useOriginalParentTree);
        }
        else {
            setNewParentTree(
                useOriginalParentTree.filter(parentTree => 
                    parentTree.name.toLowerCase().indexOf(useParentSearch.toLowerCase()) > -1
                )
            );
        }
    }, [useParentSearch]);
    useEffect(() => {
        axios({
            method: "GET",
            url: ApiURL + `/employees/parent_trees_reversed`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                let response = JSON.parse(resp.data);
                let resArr = [];
                let justNames = [];

                response.forEach((parentItem) => {
                    justNames.push(parentItem.name);
                    let parentTreeText = "";
                    let i = 0;
                    parentItem.parent_tree.forEach(
                        (parentTreeItem, parentTreeItemIndex) => {
                            if (
                                parentTreeItemIndex ==
                                parentItem.parent_tree.length - 1
                            ) {
                                parentTreeItem.name = (
                                    <b>{parentTreeItem.name}</b>
                                );
                            }
                            parentTreeText = [
                                parentTreeText,
                                parentTreeItem.name,
                            ];

                            if (
                                parentTreeItemIndex !=
                                parentItem.parent_tree.length - 1
                            ) {
                                parentTreeText = [parentTreeText, " >> "];
                            }
                        }
                    );
                    let resObject = {
                        id: parentItem.id,
                        text: parentTreeText,
                    };
                    resArr.push(resObject);
                });

                setJustNames(justNames);
                setParentTrees(resArr);
                return resArr;
            })
            .then((resArr) => {
                let allEmployeesArr = [];
                resArr.forEach((item) => {
                    allEmployeesArr.push([item.id, item.text]);
                });
                setAllEmployees(allEmployeesArr);
            });
    }, []);
    const handleParentSelect = (event) => {
        setParent(event.target.value);
    };

    // Handle Employee Create Form Send
    const handleEmployeeCreate = () => {
        notification.info("Mitarbeiter anlegen...");
        let postData = {
            name: useName,
            surname: useSurname,
            date_of_birth: useDateOfBirth,
            role: useRole,
            working_weeks: useWorkingWeeks,
            employment_relationship: useEmploymentRelationship,
            employment_type: useEmploymentType,
            department: useDepartment,
            parent: useParent,
        };
        
        axios({
            method: "POST",
            url: ApiURL + "/employees",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: postData,
        })
            .then((resp) => {
                let payload = resp.data;
                notification.success(
                    "Mitarbeiter " +
                        payload.name +
                        " " +
                        payload.surname +
                        " hinzugefügt"
                );
            })
            .then(() => {
                setReRender(Math.random());
            });
    };

    // Data Table
    const [useEditId, setEditId] = useState(0);
    const [useEditName, setEditName] = useState("");
    const [useEditSurname, setEditSurname] = useState("");
    const [useEditDateOfBirth, setEditDateOfBirth] = useState("");
    const [useEditRole, setEditRole] = useState(0);
    const [useEditWorkingWeeks, setEditWorkingWeeks] = useState("");
    const [useEditEmploymentRelationship, setEditEmploymentRelationship] =
        useState("");
    const [useEditEmploymentType, setEditEmploymentType] = useState("");
    const [useEditParent, setEditParent] = useState(0);
    const [useEditDepartment, setEditDepartment] = useState(0);

    const handleEditNameChange = (event) => setEditName(event.target.value);
    const handleEditSurnameChange = (event) =>
        setEditSurname(event.target.value);
    const handleEditDateOfBirthChange = (event) =>
        setEditDateOfBirth(event.target.value);
    const handleEditRoleChange = (event) => setEditRole(event.target.value);
    const handleEditWorkingWeeksChange = (event) =>
        setEditWorkingWeeks(event.target.value);
    const handleEditEmploymentRelationshipChange = (event) =>
        setEditEmploymentRelationship(event.target.value);
    const handleEditEmploymentTypeChange = (event) =>
        setEditEmploymentType(event.target.value);
    const handleEditParentChange = (event) => setEditParent(event.target.value);
    const handleEditDepartmentChange = (event) =>
        setEditDepartment(event.target.value);

    const handleEditButtonClick = (id) => {
        axios({
            method: "GET",
            url: ApiURL + `/employees/${id}`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                setEditId(id);
                let payload = resp.data;
                setEditName(payload.name);
                setEditSurname(payload.surname);
                setEditDateOfBirth(payload.date_of_birth);
                setEditRole(payload.role);
                setEditWorkingWeeks(payload.working_weeks);
                setEditEmploymentRelationship(payload.employment_relationship);
                setEditEmploymentType(payload.employment_type);
                setEditParent(payload.parent);
                setEditDepartment(payload.department);
            })
            .then(() => {
                setModalOpen(true);
            });
    };
    const handleDeleteButtonClick = (id) => {
        confirm(function () {
            axios({
                method: "DELETE",
                url: ApiURL + "/employees/" + id,
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            })
                .then((resp) => {
                    let payload = resp.data;
                    notification.success(payload);
                })
                .then(() => {
                    setReRender(Math.random());
                });
        });
    };

    const [useViewContent, setViewContent] = useState(<></>);
    const handleViewButtonClick = (id) => {
        handleModalContentAndOpen(id);
    };

    const [useNoteModalOpen, setNoteModalOpen] = useState(false);
    const [useNotesModalContent, setNotesModalContent] = useState(<></>);

    const handleNoteModalToggle = () => {
        setNoteModalOpen(!useNoteModalOpen);
    };

    const handleNoteButtonClick = async (id) => {
        let notes = await axios({
            method: "GET",
            url: ApiURL + `/employees/${id}/notes`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        });

        let payload = JSON.parse(notes.data);

        await setNoteAddActiveUser(id);

        setNotesModalContent(
            <TableContainer>
                <Table className={classes.table} aria-label="Anmerkungen">
                    <TableBody>
                        {payload.map((note) => {
                            return (
                                <TableRow>
                                    <TableCell>{note.date}</TableCell>
                                    <TableCell>{note.note}</TableCell>
                                    {renderIfIsAdmin(
                                        <TableCell>
                                            <div
                                                className="btn-object"
                                                onClick={() =>
                                                    handleDeleteNote(note.id)
                                                }
                                                title="Anmerkung entfernen"
                                            >
                                                <DeleteIcon />
                                            </div>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );

        handleNoteModalToggle();
    };

    const handleDeleteNote = (id) => {
        confirm(function () {
            axios({
                method: "DELETE",
                url: ApiURL + `/notes/${id}`,
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            })
                .then((resp) => {
                    let payload = resp.data;
                    notification.success(payload);
                })
                .then(() => {
                    setReRender(Math.random());
                });
        });
    };

    //! Note Add
    const [useNoteAddActiveUser, setNoteAddActiveUser] = useState(0);
    const [useNoteAddModalOpen, setNoteAddModalOpen] = useState(false);

    const handleNoteAddModalToggle = () => {
        setNoteAddModalOpen(!useNoteAddModalOpen);
    };

    const [useNoteText, setNoteText] = useState("");
    const handleNoteTextChange = (event) => {
        setNoteText(event.target.value);
    };

    const handleNoteAddSubmit = () => {
        let postData = {
            table_object: "employees",
            object_id: useNoteAddActiveUser,
            note: useNoteText,
        };
        axios({
            method: "POST",
            url: ApiURL + `/notes`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: postData,
        })
            .then((resp) => {
                notification.success("Notiz erfolgreich hinzugefügt");
            })
            .then(() => {
                setReRender(Math.random());
            });
    };

    // Holiday
    const [useHolidayModalOpen, setHolidayModalOpen] = useState(false);
    const [useHolidays, setHolidays] = useState([]);
    const [useHolidayActiveUser, setHolidayActiveUser] = useState(1);

    const handleHolidayModalToggle = () => {
        setHolidayModalOpen(!useHolidayModalOpen);
    };

    const handleHolidayButtonClick = async (id) => {
        await setHolidayActiveUser(id);
        let holidays = await axios({
            method: "GET",
            url: ApiURL + `/employees/${id}/holidays`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        });

        let payload = await JSON.parse(holidays.data);
        await setHolidays(payload);

        await handleHolidayModalToggle();
    };

    const handleDeleteHoliday = (id) => {
        confirm(async function () {
            let response = await axios({
                method: "DELETE",
                url: ApiURL + `/holidays/${id}`,
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            });

            await notification.success(response.data);
            await setReRender(Math.random());
        });
    };

    const [useHolidayAddModalOpen, setHolidayAddModalOpen] = useState(false);

    const handleHolidayAddModalToggle = () => {
        setHolidayAddModalOpen(!useHolidayAddModalOpen);
    };
    const handleHolidayAdd = () => {
        handleHolidayAddModalToggle();
    };

    const [useHolidayStartDate, setHolidayStartDate] = useState(
        moment().format("DD-MM-yyyy")
    );
    const [useHolidayEndDate, setHolidayEndDate] = useState(
        moment().format("DD-MM-yyyy")
    );

    const handleHolidayStartDate = (event) => {
        setHolidayStartDate(event.target.value);
    };

    const handleHolidayEndDate = (event) => {
        setHolidayEndDate(event.target.value);
    };

    const handleHolidayAddSubmit = () => {
        confirm(async function () {
            let data = {
                start_date: useHolidayStartDate,
                end_date: useHolidayEndDate,
            };
            let response = await axios({
                method: "POST",
                url: ApiURL + `/employees/${useHolidayActiveUser}/add_holiday`,
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
                data: data,
            });

            await notification.success("Urlaub erfolgreich hinzugefügt!");
        });
    };

    const [useDataTableData, setDataTableData] = useState([]);
    useEffect(() => {
        const datatableData = processTableData(
            useAllEmployees,
            true,
            handleEditButtonClick,
            handleDeleteButtonClick,
            handleViewButtonClick,
            handleNoteButtonClick,
            handleHolidayButtonClick,
            props.isAdmin,
            useJustNames
        );
        let arr = [];
        datatableData.forEach((tableData) => {
            arr.push([tableData[1], tableData[0], tableData[2]]);
        });
        setDataTableData(arr);
        console.log(datatableData);
    }, [useAllEmployees, useReRender]);

    // Form Edit Send
    const handleFormSend = () => {
        
        let putData = {
            name: useEditName,
            surname: useEditSurname,
            date_of_birth: useEditDateOfBirth,
            role: useEditRole,
            working_weeks: useEditWorkingWeeks,
            employment_relationship: useEditEmploymentRelationship,
            employment_type: useEditEmploymentType,
            department: useEditDepartment,
            parent: useEditParent,
        };
        axios({
            method: "PUT",
            url: ApiURL + `/employees/${useEditId}`,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            data: putData,
        })
            .then((resp) => {
                let payload = resp.data;
                
                notification.success(
                    payload.name +
                        " " +
                        payload.surname +
                        " ist erfolgreich editiert!"
                );
            })
            .then(() => {
                setModalOpen(false);
            })
            .then(() => {
                setReRender(Math.random());
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const [useLogsActiveUserName, setLogsActiveUserName] = useState("");
    // Modal
    const [useDialogModalOpen, setDialogModalOpen] = useState(false);
    const [useModalTitle, setModalTitle] = useState("");
    const [useModalContent, setModalContent] = useState(<></>);
    const [useRoles, setRoles] = useState([]);

    useEffect(() => {
        axios({
            method: "GET",
            url: ApiURL + "/return_json/constants/roles",
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then((resp) => {
            setRoles(JSON.parse(resp.data));
        });
    }, []);

    const handleModalToggle = () => {
        setDialogModalOpen(!useDialogModalOpen);
    };

    const handleModalContentAndOpen = (objectID) => {
        
        axios({
            method: "GET",
            url: ApiURL + "/employees/" + objectID,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((resp) => {
                let data = resp.data;
                setLogsActiveUser(data.id);
                return {
                    data: data,
                };
            })
            .then((ret) => {
                let parentID = ret.data.parent;
                axios({
                    method: "GET",
                    url: ApiURL + "/employees/" + parentID + "/fullname",
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    },
                })
                    .then((resp) => {
                        let name = resp.data;
                        let retObject = ret.data;
                        retObject.parent = name;
                        return retObject;
                    })
                    .then((ret) => {
                        ret.role = useRoles[ret.role];
                        
                        return ret;
                    })
                    .then(async (ret) => {
                        let department = await axios({
                            method: "GET",
                            url: ApiURL + "/departments/" + ret.department,
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        });
                        ret.department = department.data.label;
                        ret.quit = "No";
                        if (ret.has_leaved_company) {
                            ret.quit = "Yes";
                        }
                        let notes = await axios({
                            method: "GET",
                            url: ApiURL + `/employees/${ret.id}/notes`,
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        });

                        let payload = await JSON.parse(notes.data);

                        ret.notes = await payload;

                        await handleModalContent(ret);
                        setLogsActiveUserName(ret.name + " " + ret.surname);
                        await handleModalToggle();
                    });
            });
    };

    const handleModalContent = (object) => {
        setModalContent(
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-label="Mitarbeitertabelle"
                >
                    <TableBody>
                        <TableRow>
                            <TableCell>ID: </TableCell>
                            <TableCell>{object.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Vorname: </TableCell>
                            <TableCell>{object.surname}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name: </TableCell>
                            <TableCell>{object.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Geburtsdatum: </TableCell>
                            <TableCell>{object.date_of_birth}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Vorgesetzer: </TableCell>
                            <TableCell>{object.parent}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Rolle: </TableCell>
                            <TableCell>{object.role}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Arbeitswochen: </TableCell>
                            <TableCell>{object.working_weeks}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Arbeitsverhältnis: </TableCell>
                            <TableCell>
                                {object.employment_relationship}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Beschäftigungsverhältnis: </TableCell>
                            <TableCell>{object.employment_type}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Abteilung: </TableCell>
                            <TableCell>{object.department}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Beschäftigungsverhältnis beendet?:{" "}
                            </TableCell>
                            <TableCell>{object.quit}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Anmerkungen</TableCell>
                        </TableRow>
                        {object.notes.map((note) => {
                            return (
                                <TableRow>
                                    <TableCell>{note.date}</TableCell>
                                    <TableCell>{note.note}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    // Logs Modal
    const [useLogsDialogModalOpen, setLogsDialogModalOpen] = useState(false);
    const [useLogs, setLogs] = useState([]);
    const [useLogsActiveUser, setLogsActiveUser] = useState(1);
    const [useLogsPage, setLogsPage] = useState(1);

    const handleLogsModalToggle = () => {
        setLogsDialogModalOpen(!useLogsDialogModalOpen);
        setLogsPage(1);
    };

    useEffect(() => {
        if (!useLogsDialogModalOpen) {
            
            setLogsPage(1);
        }
    }, [useLogsActiveUser]);

    const handleLogModalOpen = () => {
        handleLogsModalToggle();
    };

    useEffect(() => {
        axios({
            method: "GET",
            url: ApiURL + `/employees/${useLogsActiveUser}/logs`,
            headers: {
                Authorization: `Bearer ${props.token},`,
            },
        })
            .then((resp) => {
                let payload = JSON.parse(resp.data);
                setLogs(payload);
                return payload;
            })
            .then((res) => {
                
            });
    }, [useLogsActiveUser]);

    const handleLogLoadMore = () => {
        let page = useLogsPage + 1;
        setLogsPage(page);
        axios({
            method: "GET",
            url: ApiURL + `/employees/${useLogsActiveUser}/logs?page=${page}`,
            headers: {
                Authorization: `Bearer ${props.token},`,
            },
        }).then((resp) => {
            let payload = JSON.parse(resp.data);
            if (payload.length > 0) {
                
                let logs = useLogs;
                logs = logs.concat(JSON.parse(resp.data));
                setLogs(logs);
            }
        });
    };

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    //:y !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    useEffect(() => {
        setNoteText("");
    }, [useReRender]);
    //:y !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    const [useNewParentTree, setNewParentTree] = useState([]);
    const [useOriginalParentTree, setOriginalParentTree] = useState([]);
    const roles = [
        "Mitarbeiter",
        "Vorarbeiter",
        "Meister",
        "Abteilungsleiter",
        "Produktionsleiter",
    ];
    useEffect(() => {
        axios({
            method: "GET",
            url:
                ApiURL +
                `/employees/all_parent_tree_of_item?department=` +
                useDepartment +
                `&role=` +
                useRole,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        }).then((resp) => {
            setNewParentTree(resp.data);
            setOriginalParentTree(resp.data);
            setOriginalEditParentTree(resp.data);
        });
    }, [useRole, useDepartment]);

    const [useNewEditParentTree, setNewEditParentTree] = useState([]);
    const [useOriginalEditParentTree, setOriginalEditParentTree] = useState([]);
    const [useEditParentSearch, setEditParentSearch] = useState("");
    useEffect(() => {
        if (useEditParentSearch.length > 0) {
            let newParentTree = useOriginalParentTree.filter((item) => {
                return item.name
                    .toLowerCase()
                    .includes(useEditParentSearch.toLowerCase());
            });
            setNewEditParentTree(newParentTree);
        } else {
            setNewEditParentTree(useOriginalParentTree);
        }
    }, [useEditParentSearch]);
    useEffect(() => {
        const params = {
            method: "GET",
            url:
                ApiURL +
                `/employees/all_parent_tree_of_item?department=` +
                useEditDepartment +
                `&role=` +
                useEditRole,
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        };
        axios(params).then((resp) => {
            setNewEditParentTree(resp.data);
        });
    }, [useEditRole, useEditDepartment]);

    return (
        <>
            {renderIf(
                props.isAdmin,
                <Accordion
                    square
                    expanded={useExpanded === "panel1"}
                    onChange={handleChange("panel1")}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ExpandIcon />}
                    >
                        <Typography>Arbeitnehmer hinzufügen</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form
                            onSubmit={handleEmployeeCreate}
                            className={classes.form}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                onChange={handleNameChange}
                                id="name"
                                label="Name"
                                required
                                value={useName}
                            />
                            <TextField
                                onChange={handleSurnameChange}
                                id="surname"
                                label="Vorname"
                                required
                                value={useSurname}
                            />
                            <TextField
                                id="date"
                                label="Geburtsdatum"
                                type="date"
                                
                                value={useDateOfBirth}
                                className={classes.textField}
                                onChange={handleDateOfBirthChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id="role-label">
                                    Rolle
                                </InputLabel>
                                <Select
                                    labelId="role-label"
                                    id="role"
                                    value={useRole}
                                    onChange={handleRoleSelect}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    required
                                >
                                    {roles.map((role, roleIndex) => (
                                        <MenuItem key={"role-" + roleIndex} value={roleIndex}>{role}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <br />
                            <TextField
                                onChange={handleWorkingWeeksChange}
                                id="working-weeks"
                                label="Arbeitswochen"
                                value={useWorkingWeeks}
                                helperText="Schreiben Sie bitte in dieser Notation 1-30.32-52. Das bedeutet: Arbeiter wird in der 31. und 32. Woche nicht arbeiten."
                                required
                            />

                            <FormControl className={classes.formControl}>
                                <InputLabel
                                    shrink
                                    id="employmentRelationshipLabel"
                                >
                                    Arbeitsverhältnis
                                </InputLabel>
                                <Select
                                    labelId="employmentRelationshipLabel"
                                    id="employmentRelationship"
                                    value={useEmploymentRelationship}
                                    onChange={
                                        handleEmploymentRelationshipChange
                                    }
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    required
                                >
                                    <MenuItem
                                        key="Mitarbeiter"
                                        value={"Mitarbeiter"}
                                    >
                                        Mitarbeiter
                                    </MenuItem>
                                    <MenuItem
                                        key="Leihmitarbeiter"
                                        value={"Leihmitarbeiter"}
                                    >
                                        Leihmitarbeiter
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                onChange={handleEmploymentTypeChange}
                                id="employment-type"
                                label="Beschäftigungsverhältnis"
                                value={useEmploymentType}
                            />
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink id="departments-label">
                                    Abteilung
                                </InputLabel>
                                <Select
                                    labelId="departments-label"
                                    id="department"
                                    value={useDepartment}
                                    onChange={handleDepartmentSelect}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    required
                                >
                                    <TextField
                                        size="small" autoFocus fullWidth placeholder="Tippe um zu suchen..." InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)}}
                                        inputProps={{
                                            startadornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)
                                        }}
                                        onChange={(e) => setDepartmentSearch(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== "Escape") { e.stopPropagation();}
                                        }}
                                    />
                                    {useDepartments.map(
                                        (department, departmentIndex) => (
                                            <MenuItem value={department[0]}>
                                                {department[1]}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                            <br />
                            <FormControl
                                className={classes.formControl}
                                style={{ width: "100%" }}
                            >
                                <InputLabel shrink id="parent-label">
                                    Vorgesetzter
                                </InputLabel>
                                <Select
                                    labelId="parent-label"
                                    id="parent"
                                    value={useParent}
                                    onChange={handleParentSelect}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    required
                                >
                                    <TextField
                                        size="small" autoFocus fullWidth placeholder="Tippe um zu suchen..." InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)}}
                                        inputProps={{
                                            startadornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)
                                        }}
                                        onChange={(e) => setParentSearch(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== "Escape") { e.stopPropagation();}
                                        }}
                                    />
                                    {useNewParentTree.map(
                                        (parent, parentIndex) => (
                                            <MenuItem value={parent.id}>
                                                {parent.name} {parent.surname}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                            <br />
                            <Button
                                variant="contained"
                                size="medium"
                                color="secondary"
                                type="submit"
                            >
                                Speichern
                            </Button>
                        </form>
                    </AccordionDetails>
                </Accordion>
            )}
            <Accordion
                square
                expanded={useExpanded === "panel2"}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    expandIcon={<ExpandIcon />}
                >
                    <Typography>Belegschaft</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title="Arbeiterliste"
                            data={useDataTableData}
                            columns={["Name", "Hierarchie", "Bearbeitung"]}
                            options={{
                                filterType: "checkbox",
                                rowsPerPage: 10,
                                rowsPerPageOptions: [
                                    5,
                                    10,
                                    useDataTableData.length,
                                ],
                                print: false,
                                fixedHeader: true,
                            }}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Dialog
                open={useModalOpen}
                onClose={handleModalClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Editieren {useEditName}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        
                        autoFocus
                        margin="dense"
                        id="editName"
                        label="Name"
                        type="text"
                        fullWidth
                        onChange={handleEditNameChange}
                        value={useEditName}
                    />
                    <TextField
                        
                        autoFocus
                        margin="dense"
                        id="editSurname"
                        label="Vorname"
                        type="text"
                        fullWidth
                        onChange={handleEditSurnameChange}
                        value={useEditSurname}
                    />
                    <TextField
                        id="editDate"
                        label="Geburtsdatum"
                        type="date"
                        
                        value={useEditDateOfBirth}
                        className={classes.textField}
                        onChange={handleEditDateOfBirthChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <br />
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink id="edit-role-label">
                            Rolle
                        </InputLabel>
                        <Select
                            labelId="edit-role-label"
                            id="editRole"
                            value={useEditRole}
                            onChange={handleEditRoleChange}
                            displayEmpty
                            className={classes.selectEmpty}
                            required
                        >
                            {roles.map((role, roleIndex) => (
                                <MenuItem key={"role2-" + roleIndex } value={roleIndex}>{role}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        id="editWorkingWeeks"
                        label="Working Weeks"
                        type="text"
                        margin="dense"
                        
                        value={useEditWorkingWeeks}
                        className={classes.textField}
                        onChange={handleEditWorkingWeeksChange}
                        fullWidth
                    />
                    <TextField
                        id="employmentRelationship"
                        label="Arbeitsverhältnis"
                        type="text"
                        margin="dense"
                        
                        value={useEmploymentRelationship}
                        className={classes.textField}
                        onChange={handleEditEmploymentRelationshipChange}
                        fullWidth
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink id="employmentRelationshipLabel">
                            Arbeitsverhältnis
                        </InputLabel>
                        <Select
                            labelId="employmentRelationshipLabel"
                            id="employmentRelationship"
                            value={useEditEmploymentRelationship}
                            onChange={handleEditEmploymentRelationshipChange}
                            displayEmpty
                            className={classes.selectEmpty}
                            required
                        >
                            <MenuItem key="Mitarbeiter" value={"Mitarbeiter"}>
                                Mitarbeiter
                            </MenuItem>
                            <MenuItem
                                key="Leihmitarbeiter"
                                value={"Leihmitarbeiter"}
                            >
                                Leihmitarbeiter
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        id="employmentType"
                        label="Beschäftigungsverhältnis"
                        type="text"
                        margin="dense"
                        
                        value={useEmploymentType}
                        className={classes.textField}
                        onChange={handleEmploymentTypeChange}
                        fullWidth
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink id="edit-departments-label">
                            Abteilung
                        </InputLabel>
                        <Select
                            labelId="edit-departments-label"
                            id="editDepartment"
                            value={useEditDepartment}
                            onChange={handleEditDepartmentChange}
                            displayEmpty
                            className={classes.selectEmpty}
                            required
                            fullWidth
                        >
                            {useDepartments.map(
                                (department, departmentIndex) => (
                                    <MenuItem value={department[0]}>
                                        {department[1]}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                    <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                    >
                        <InputLabel shrink id="edit-parent-label">
                            Vorgesetzter
                        </InputLabel>
                        <Select
                            labelId="edit-parent-label"
                            id="editParent"
                            value={useEditParent}
                            onChange={handleEditParentChange}
                            displayEmpty
                            className={classes.selectEmpty}
                            required
                            fullWidth
                        >
                            <TextField
                                size="small" autoFocus fullWidth placeholder="Tippe um zu suchen..." InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)}}
                                inputProps={{
                                    startadornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)
                                }}
                                onChange={(e) => setEditParentSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") { e.stopPropagation();}
                                }}
                            />
                            {useNewEditParentTree.map((parent, parentIndex) => (
                                <MenuItem value={parent.id}>
                                    {parent.name} {parent.surname}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleFormSend} color="primary">
                        Senden
                    </Button>
                </DialogActions>
            </Dialog>
            {/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
            <Dialog
                open={useDialogModalOpen}
                onClose={handleModalToggle}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {useModalTitle}
                </DialogTitle>
                <DialogContent>{useModalContent}</DialogContent>
                <DialogActions>
                    <Button onClick={handleLogModalOpen} color="primary">
                        Protokolle öffnen
                    </Button>
                    <Button onClick={handleModalToggle} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={useLogsDialogModalOpen}
                onClose={handleLogsModalToggle}
                aria-labelledby="logs-dialog-title"
            >
                <DialogTitle id="logs-dialog-title">
                    {useLogsActiveUserName}
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label="Mitarbeiterprotokolltabelle"
                        >
                            <TableBody>
                                {useLogs.map((log) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{log.log}</TableCell>
                                            <TableCell>{log.date}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                <TableRow
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        onClick={handleLogLoadMore}
                                        color="primary"
                                        style={{ display: "flex" }}
                                    >
                                        Mehr anzeigen
                                    </Button>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogsModalToggle} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
            {/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
            {/* NOTES */}
            <Dialog
                open={useNoteModalOpen}
                onClose={handleNoteModalToggle}
                aria-labelledby="form-dialog-note"
            >
                <DialogTitle id="form-dialog-note">Hinzufügen</DialogTitle>
                <DialogContent>{useNotesModalContent}</DialogContent>
                <DialogActions>
                    {renderIfIsAdmin(
                        <Button
                            onClick={handleNoteAddModalToggle}
                            color="secondary"
                        >
                            Notiz hinzufügen
                        </Button>
                    )}
                    <Button onClick={handleNoteModalToggle} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
            {/* NOTE ADD */}
            <Dialog
                open={useNoteAddModalOpen}
                onClose={handleNoteAddModalToggle}
                aria-labelledby="form-dialog-note"
            >
                <form
                    onSubmit={handleNoteAddSubmit}
                    className={classes.form}
                    noValidate
                    autoComplete="off"
                >
                    <DialogTitle id="form-dialog-note">
                        Notiz hinzufügen
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            onChange={handleNoteTextChange}
                            id="note"
                            label="Anmerkung"
                            required
                            value={useNoteText}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleNoteAddSubmit} color="secondary">
                            Speichern
                        </Button>
                        <Button
                            onClick={handleNoteAddModalToggle}
                            color="primary"
                        >
                            Schließen
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {/* HOLIDAY */}
            <Dialog
                open={useHolidayModalOpen}
                onClose={handleHolidayModalToggle}
                aria-labelledby="form-dialog-note"
            >
                <DialogTitle id="form-dialog-holiday">Urlaubstage</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label="Employee Log Table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Urlaubsbeginn</TableCell>
                                    <TableCell>Urlaubsende</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {useHolidays.map((holiday, holidayKey) => {
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                {holidayKey + 1}
                                            </TableCell>
                                            <TableCell>
                                                {holiday.holiday_date_start}
                                            </TableCell>
                                            <TableCell>
                                                {holiday.holiday_date_end}
                                            </TableCell>
                                            {renderIfIsAdmin(
                                                <TableCell>
                                                    <div
                                                        className="btn-object"
                                                        onClick={() =>
                                                            handleDeleteHoliday(
                                                                holiday.id
                                                            )
                                                        }
                                                        title="Diesen Urlaub entfernen"
                                                    >
                                                        <DeleteIcon />
                                                    </div>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    {renderIfIsAdmin(
                        <Button onClick={handleHolidayAdd} color="secondary">
                            Füge Urlaub hinzu
                        </Button>
                    )}
                    <Button onClick={handleHolidayModalToggle} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
            {/* HOLIDAY ADD */}
            <Dialog
                open={useHolidayAddModalOpen}
                onClose={handleHolidayAddModalToggle}
                aria-labelledby="form-dialog-holiday-add"
            >
                <form
                    onSubmit={handleHolidayAddSubmit}
                    className={classes.form}
                    noValidate
                    autoComplete="off"
                >
                    <DialogTitle id="form-dialog-holiday-add">
                        Füge Urlaub hinzu
                    </DialogTitle>
                    <DialogContent style={{ padding: "10px" }}>
                        <TextField
                            id="holidayStartDate"
                            label="Startdatum des Urlaubs"
                            type="date"
                            
                            value={useHolidayStartDate}
                            className={classes.textField}
                            onChange={handleHolidayStartDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="holidayEndDate"
                            label="Enddatum des Urlaubs"
                            type="date"
                            
                            value={useHolidayEndDate}
                            className={classes.textField}
                            onChange={handleHolidayEndDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleHolidayAddSubmit}
                            color="secondary"
                        >
                            Speichern
                        </Button>
                        <Button
                            onClick={handleHolidayAddModalToggle}
                            color="primary"
                        >
                            Schließen
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
