import React from "react";
import axios from "axios";
import notification from "../helpers/SnackbarUtils";

const ApiURL = process.env.REACT_APP_ROOT;

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return { ...state, isAuthenticated: true };
        case "SIGN_OUT_SUCCESS":
            return { ...state, isAuthenticated: false };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function UserProvider({ children }) {

    var [state, dispatch] = React.useReducer(userReducer, {
        isAuthenticated: !!localStorage.getItem("id_token"),
    });

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function useUserState() {
    var context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
    }

    return context;
}

function useUserDispatch() {
    var context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error("useUserDispatch must be used within a UserProvider");
    }
    return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
    setError(false);
    setIsLoading(true);

    if (!!login && !!password) {
        axios({
            method: "POST",
            url: ApiURL + "/login",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            data: {
                username: login,
                password: password,
            },
        })
            .then((resp) => {
                notification.success(
                    "Die Anmeldung ist erfolgreich. Sie werden weitergeleitet..."
                );
                let payload = resp.data;
                let token = payload.token;
                setTimeout(() => {
                    localStorage.setItem("token", token);
                    setError(null);
                    setIsLoading(false);
                    dispatch({ type: "LOGIN_SUCCESS" });

                    history.push("/app/dashboard");
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }, 1000);
            })
            .catch(function (error) {
                notification.error("Falscher Benutzername oder Passwort");
                setIsLoading(false);
            });
        /*
    
    */
    } else {
        dispatch({ type: "LOGIN_FAILURE" });
        setError(true);
        setIsLoading(false);
    }
}

function signOut(dispatch, history) {
    localStorage.removeItem("token");
    dispatch({ type: "SIGN_OUT_SUCCESS" });
    history.push("/login");
    setTimeout(() => {
        window.location.reload();
    }, 500);
}
